import LOG from 'logging/Logger';

export const saveLocal = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocal = <T>(key: string): T | undefined => {
  const value = localStorage.getItem(key);
  if (!value) {
    return undefined;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    LOG.error(e);
  }

  return undefined;
};
