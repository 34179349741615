import { Fragment, memo, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SectionCard from "components/SectionCard";
import { useAppDispatch, useAppSelector } from "app/hooks";

import {
  createRoom,
  getMetadata,
  initCall,
  joinRoom,
  selectCallingState,
  setApiKey,
  setCameraState,
  setMicrophoneState,
  setRealtimeGraphQLEndpoint,
  setRoomCode,
  updateRoom,
  clearError,
  searchContent,
  updateContent,
  setEnvironmentSettings,
  setGraphQLEndpoint,
  subscribeToWTCall,
  subscribeToCallEvents,
  setSpeaker,
  setMicrophone,
  setCamera,
} from "./slices/callingSlice";
import RoomDetailsForm from "components/calling/RoomDetailsForm";
import ParticipationDetailsForm from "components/calling/ParticipationDetailsForm";
import EnvironmentSettingsForm from "components/calling/EnvironmentSettingsForm";
import dayjs from "dayjs";
import LoadingBackdrop from "components/LoadingBackdrop";
import ContentMetadata from "components/calling/ContentMetadata";
import PlaybackControls from "components/calling/PlaybackControls";
import RoomEventsTable from "components/calling/RoomEventsTable";
import ParticipantEventsTable from "components/calling/ParticipantEventsTable";
import ChangeContentDialog from "components/calling/ChangeContentDialog";
import { detect } from "detect-browser";
import { ParticipantsVideoGallery } from "components/calling/ParticipantsVideoGallery";
import { PeripheralSelect } from "components/calling/PeripheralSelect";
import { toastError } from "util/toast";

const CallingPage = memo(() => {
  const callingState = useAppSelector(selectCallingState);
  const dispatch = useAppDispatch();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [changeContentDialogOpen, setChangeContentDialogOpen] = useState(false);
  const browser = detect();

  useEffect(() => {
    if (!callingState.wtCallSubscriptionInitialised) {
      dispatch(subscribeToWTCall());
    }
  }, [callingState.wtCallSubscriptionInitialised, dispatch]);

  useEffect(() => {
    if (callingState.room) {
      dispatch(getMetadata(callingState.room));
    }
  }, [callingState.room, dispatch]);

  useEffect(() => {
    if (callingState.room && !callingState.participationDetails) {
      dispatch(
        joinRoom({
          roomCode: callingState.room.roomCode,
          loadRoom: false,
        })
      );
    }
  }, [callingState.room, callingState.participationDetails, dispatch]);

  useEffect(() => {
    if (
      callingState.participationDetails &&
      callingState.room &&
      !callingState.callInitialised
    ) {
      dispatch(initCall({}));
    }
  }, [
    callingState.apiDetails,
    callingState.participationDetails,
    callingState.room,
    callingState.callInitialised,
    dispatch,
  ]);

  useEffect(() => {
    if (
      callingState.participationDetails &&
      callingState.room &&
      !callingState.callSubscriptionInitialised
    ) {
      dispatch(subscribeToCallEvents(callingState.room.roomCode));
    }
  }, [
    callingState.participationDetails,
    callingState.room,
    callingState.callSubscriptionInitialised,
    dispatch,
  ]);

  useEffect(() => {
    if (callingState.error) {
      toastError(callingState.error);
      dispatch(clearError({}));
    }
  }, [callingState.error, dispatch]);

  useEffect(() => {
    setLoadingOpen(callingState.loading);
  }, [callingState.loading]);

  return (
    <Stack>
      <LoadingBackdrop open={loadingOpen}></LoadingBackdrop>
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SectionCard>
              <Typography align="center" variant="h5">
                Settings
              </Typography>
              <EnvironmentSettingsForm
                selectedEnvironment={callingState.apiEnvironment}
                apiDetails={callingState.apiDetails}
                onEnvironmentSelected={function (env: string): void {
                  dispatch(setEnvironmentSettings(env));
                }}
                onGraphQLEndpointChanged={function (endpoint: string): void {
                  dispatch(setGraphQLEndpoint(endpoint));
                }}
                onRealtimeGraphQLEndpointChanged={function (
                  endpoint: string
                ): void {
                  dispatch(setRealtimeGraphQLEndpoint(endpoint));
                }}
                onApiKeyChanged={function (apiKey: string): void {
                  dispatch(setApiKey(apiKey));
                }}
              />
            </SectionCard>
          </Grid>
          <Grid item xs={6}>
            <SectionCard>
              <Typography align="center" variant="h5">
                Create / Join room
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(createRoom());
                }}
                sx={{
                  marginTop: 2,
                }}
              >
                Create room
              </Button>
              <Divider>OR</Divider>
              <FormControl fullWidth>
                <TextField
                  id="calling-join-room-share-link"
                  label="Room code"
                  variant="standard"
                  value={callingState.roomCode}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(setRoomCode(event.target.value));
                  }}
                />
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(
                    joinRoom({
                      roomCode: callingState.roomCode,
                      loadRoom: true,
                    })
                  );
                }}
                sx={{
                  marginTop: 2,
                }}
              >
                Join room
              </Button>
            </SectionCard>
          </Grid>
          <Grid item xs={6}>
            {callingState.room ? (
              <SectionCard>
                <Typography align="center" variant="h5">
                  Room details
                </Typography>
                <RoomDetailsForm room={callingState.room}></RoomDetailsForm>
              </SectionCard>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={6}>
            {callingState.participationDetails ? (
              <SectionCard>
                <Typography align="center" variant="h5">
                  Participation details
                  <ParticipationDetailsForm
                    participationDetails={callingState.participationDetails}
                  />
                </Typography>
              </SectionCard>
            ) : (
              <div />
            )}
          </Grid>
          {callingState.participationDetails ? (
            <Grid item xs={6}>
              <SectionCard>
                <Typography align="center" variant="h5">
                  Content metadata
                </Typography>

                <ContentMetadata
                  contentMetadata={callingState.contentMetadata}
                />

                <Box textAlign="center" sx={{ marginTop: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setChangeContentDialogOpen(true);
                    }}
                  >
                    Change content
                  </Button>
                </Box>
              </SectionCard>
            </Grid>
          ) : (
            <div />
          )}
          {callingState.participationDetails ? (
            <Grid item xs={6}>
              <SectionCard>
                <Typography align="center" variant="h5">
                  Content control
                </Typography>
                {callingState.room?.playbackOffset !== undefined ? (
                  <Typography variant="body1">
                    Playback offset:
                    {dayjs(callingState.room?.playbackOffset).format(
                      " HH:mm:ss:SSS"
                    )}
                  </Typography>
                ) : (
                  ""
                )}

                {callingState.room?.isPlaying !== undefined ? (
                  <Typography variant="body1">
                    Is playing:
                    {callingState.room?.isPlaying ? " Yes" : " No"}
                  </Typography>
                ) : (
                  ""
                )}
                <PlaybackControls
                  onPlaybackStateChanged={(isPlaying: boolean) => {
                    dispatch(
                      updateRoom({
                        isPlaying: isPlaying,
                      })
                    );
                  }}
                  onPlaybackOffsetChanged={(playbackOffset: number) => {
                    dispatch(
                      updateRoom({
                        playbackOffset: playbackOffset,
                      })
                    );
                  }}
                  room={callingState.room!}
                ></PlaybackControls>
              </SectionCard>
            </Grid>
          ) : (
            <div />
          )}
          {callingState.participationDetails ? (
            <Grid item xs={12}>
              <SectionCard>
                <Typography align="center" variant="h5">
                  Participants gallery
                </Typography>
                {browser?.name === "safari" ? (
                  <Alert severity="warning">
                    You're using safari. You can use your camera output in the
                    latest tab in your browser.
                  </Alert>
                ) : (
                  ""
                )}
                <ParticipantsVideoGallery
                  localParticipant={callingState.localParticipant}
                  remoteParticipants={callingState.remoteParticipants}
                />

                <Box textAlign="center" sx={{ marginTop: 4 }}>
                  <Grid container spacing={2}>
                    {callingState.speakers.length > 0 ? (
                      <Grid item xs={4}>
                        <PeripheralSelect
                          label={"Speaker"}
                          peripherals={callingState.speakers}
                          selectedPeripheral={callingState.selectedSpeaker}
                          onPeripheralSelected={(item) => {
                            dispatch(setSpeaker(item));
                          }}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {callingState.microphones.length > 0 ? (
                      <Grid item xs={4}>
                        <PeripheralSelect
                          label={"Microphone"}
                          peripherals={callingState.microphones}
                          selectedPeripheral={callingState.selectedMicrophone}
                          onPeripheralSelected={(item) => {
                            dispatch(setMicrophone(item));
                          }}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}

                    {callingState.cameras.length > 0 ? (
                      <Grid item xs={4}>
                        <PeripheralSelect
                          label={"Camera"}
                          peripherals={callingState.cameras}
                          selectedPeripheral={callingState.selectedCamera}
                          onPeripheralSelected={(item) => {
                            dispatch(setCamera(item));
                          }}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Button
                    variant="outlined"
                    sx={{ marginTop: 4 }}
                    onClick={() => {
                      dispatch(
                        setMicrophoneState({
                          state: !callingState.microphoneEnabled,
                        })
                      );
                    }}
                  >
                    {callingState.microphoneEnabled ? "Mute mic" : "Unmute mic"}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginTop: 4 }}
                    onClick={() => {
                      dispatch(
                        setCameraState({
                          state: !callingState.cameraEnabled,
                        })
                      );
                    }}
                  >
                    {callingState.cameraEnabled ? "Disable cam" : "Enable cam"}
                  </Button>
                </Box>
              </SectionCard>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            {callingState.participationDetails ? (
              <SectionCard>
                <Typography align="center" variant="h5">
                  Room events
                </Typography>
                <Box sx={{ height: 400, width: "100%" }}>
                  <RoomEventsTable roomEvents={callingState.roomEvents} />
                </Box>
              </SectionCard>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={12}>
            {callingState.participationDetails ? (
              <SectionCard>
                <Typography align="center" variant="h5">
                  Participant events
                </Typography>
                <Box sx={{ height: 400, width: "100%" }}>
                  <ParticipantEventsTable
                    participantEvents={callingState.participantEvents}
                  />
                </Box>
              </SectionCard>
            ) : (
              <div />
            )}
          </Grid>
        </Grid>
      </Fragment>
      <ChangeContentDialog
        open={changeContentDialogOpen}
        handleClose={() => {
          setChangeContentDialogOpen(false);
        }}
        search={(text) => {
          dispatch(searchContent(text));
        }}
        contentResult={callingState.searchContentResult}
        selectContent={(content) => {
          dispatch(
            updateContent({
              contentUuid: content.uuid,
            })
          );
          setChangeContentDialogOpen(false);
        }}
      />
    </Stack>
  );
});

export default CallingPage;
