/**
 * Create the header properties required for API Key authentication
 * @param {String} graphQLEndpoint The graphQL endpoint
 * @param {String} apiKey The api key
 * @returns
 */
export function createAPIKeyAuthHeader(graphQLEndpoint: any, apiKey: any) {
  const urlParser = new URL(graphQLEndpoint);
  return {
    host: urlParser.host,
    "x-api-key": apiKey,
  };
}
