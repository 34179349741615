import { Toaster } from 'react-hot-toast';

const ToastContainer = () => (
  <div aria-live="assertive">
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        style: {
          maxWidth: 500,
        },
      }}
    />
  </div>
);

export default ToastContainer;
