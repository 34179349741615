import React, { Fragment, memo } from 'react';
import LOG from '../logging/Logger';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import Logo from './Logo';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default memo(() => {
  LOG.debug('Render: Splash');

  return (
    <Fragment>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 'calc(10px + 2vmin)',
          color: 'white',
        }}
      >
        <Box
          sx={{
            pointerEvents: 'none',
            animation: `${spin} 2s infinite ease`,
          }}
        >
          <Logo />
        </Box>
      </Box>
    </Fragment>
  );
});
