import { Fragment, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';
import LOG from '../../logging/Logger';
import { DefaultValues, FieldValues } from 'react-hook-form';
import { useValidationForm } from 'hooks/useValidationForm';
import { FormContextProvider } from './FormContextProvider';
import { convertCamelCaseToKebabCase } from 'util/dataAttributes';
import FormErrorItem from './FormErrorItem';

interface FormDialogProps {
  name: string;
  title: string;
  open: boolean;
  onSave(data: FieldValues): void;
  onCancel(): void;
  validationRules?: { [key: string]: any };
  defaultValues?: DefaultValues<FieldValues>;
  children: ReactNode[] | ReactNode;
  allowSaveUnchanged?: boolean;
  maxWidth?: Breakpoint | false;
  error?: string;
  saveButtonText?: string;
}

const FormDialogInstance = memo(
  ({
    name,
    title,
    open,
    onSave,
    onCancel,
    children,
    validationRules,
    defaultValues,
    allowSaveUnchanged = false,
    maxWidth = 'sm',
    error,
    saveButtonText = 'Save',
  }: FormDialogProps) => {
    LOG.debug('Rendering: FormDialogInstance');

    const [hasBeenSaved, setHasBeenSaved] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'), {
      noSsr: true,
    });

    const methods = useValidationForm(validationRules, defaultValues);

    const {
      handleSubmit,
      formState: { isDirty, isValid },
      reset,
    } = methods;

    useEffect(() => {
      reset(defaultValues);
    }, [reset, defaultValues]);

    const handleSave = useCallback(
      (data: FieldValues) => {
        setHasBeenSaved(true);
        onSave(data);
      },
      [onSave]
    );

    return (
      <Dialog
        data-cy={convertCamelCaseToKebabCase(name)}
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={onCancel}
        maxWidth={maxWidth}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <FormContextProvider name={name} methods={methods}>
            <FormErrorItem error={error} />
            {children}
          </FormContextProvider>
        </DialogContent>
        <DialogActions>
          {hasBeenSaved && !isDirty ? (
            <Button data-cy={`${convertCamelCaseToKebabCase(name)}-close`} onClick={onCancel}>
              Close
            </Button>
          ) : (
            <Fragment>
              <Button
                data-cy={`${convertCamelCaseToKebabCase(name)}-cancel`}
                onClick={onCancel}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                data-cy={`${convertCamelCaseToKebabCase(name)}-save`}
                disabled={(!allowSaveUnchanged && !isDirty) || !isValid}
                onClick={handleSubmit((data) => handleSave(data))}
              >
                {saveButtonText}
              </Button>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

// Can't find a nicer way around this, we want a hard reset on the form each time it's opened
// Otherwise we get a flicker as the form is loaded with 'old' values, then update to the new ones
const FormDialog = memo(({ open, ...restProps }: FormDialogProps) =>
  open ? <FormDialogInstance open={open} {...restProps} /> : <Fragment />
);

export default FormDialog;
