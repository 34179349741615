import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Splash from "./components/Splash";
import { navigation, NavigationItem } from "./data/navigation";
import { useNavigate } from "react-router-dom";
import { pathToRegexp } from "path-to-regexp";
import { Auth } from "@aws-amplify/auth";
import LOG from "./logging/Logger";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { usePermissions } from "permissions/PermissionProvider";
import Navigation from "layout/Navigation";
import Main from "layout/Main";
import Footer from "layout/Footer";
import { useMountEffect } from "app/hooks";
const WatchTogetherApp = memo(() => {
  LOG.debug("Rendering: WatchTogetherApp");

  const [isUserLoading, setUserLoading] = useState(true);
  const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);
  const [userNavigation, setUserNavigation] = useState<NavigationItem[]>([]);
  const [navigationItem, setNavigationItem] = useState<
    NavigationItem | undefined
  >(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const isLoading = useMemo(() => isUserLoading, [isUserLoading]);

  const logout = useCallback(async () => {
    await Auth.signOut();
  }, []);

  const handleSelect = useCallback(
    (item: NavigationItem) => {
      LOG.info("handleSelect", item);

      navigate(item.defaultPath ? item.defaultPath : item.paths[0]);
    },
    [navigate]
  );

  const permissions = usePermissions();

  useEffect(() => {
    const item = navigation.find((element) => {
      for (const path of element.paths) {
        if (pathToRegexp(path).test(location.pathname)) {
          return true;
        }
      }
      return false;
    });

    let selectedItem = item;

    if (selectedItem) {
      if (!_.isEqual(selectedItem, navigationItem)) {
        scrollRef && scrollRef.scrollTo(0, 0);
        setNavigationItem(selectedItem);
      }
    }
  }, [location, navigationItem, scrollRef]);

  useMountEffect(() => {
    async function loadUser() {
      setUserLoading(true);

      const navigationsFiltered = navigation.filter((item) => {
        return (
          item.group === undefined || permissions.groups.includes(item.group)
        );
      });

      setUserNavigation(navigationsFiltered);
      setUserLoading(false);
    }
    loadUser();
  });

  if (isLoading) {
    return <Splash />;
  }

  return (
    <Fragment>
      <Navigation
        isCallingBackend={false}
        selectedItemId={navigationItem ? navigationItem.id : undefined}
        selectedItemTab={navigationItem ? navigationItem.tab : undefined}
        navigation={userNavigation}
        onSelect={handleSelect}
        logout={logout}
      />
      <Main
        navigation={userNavigation}
        navigationItem={navigationItem}
        setScrollRef={setScrollRef}
      />
      <Footer />
    </Fragment>
  );
});

export default WatchTogetherApp;
