import { joiResolver } from '@hookform/resolvers/joi';
import {
  FieldPath,
  useController,
  UseControllerProps,
  UseControllerReturn,
  useForm,
} from 'react-hook-form';
import { useContext, useMemo } from 'react';
import { DefaultValues, FieldValues } from 'react-hook-form';
import joi from 'joi';
import { FormContext } from 'components/form/FormContextProvider';
import { convertCamelCaseToKebabCase } from 'util/dataAttributes';

const useValidationForm = <TFieldValues extends FieldValues = FieldValues>(
  validationRules?: { [key: string]: any },
  defaultValues?: DefaultValues<TFieldValues>
) => {
  const schema = useMemo(
    () =>
      validationRules
        ? joi
            .object({ ...validationRules, _meta: joi.object() })
            .messages({ 'any.custom': '{{#error.message}}' })
            .required()
        : undefined,
    [validationRules]
  );

  const methods = useForm<TFieldValues>({
    resolver: schema ? joiResolver(schema) : undefined,
    mode: 'all',
    defaultValues,
  });

  return methods;
};

export const useValidationFormContext = () => {
  const context = useContext(FormContext);

  const formId = `${convertCamelCaseToKebabCase(context?.name ?? 'form')}`;

  return { formId };
};

export const useValidationController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UseControllerProps<TFieldValues, TName>
): UseControllerReturn<TFieldValues, TName> & { formId: string; id: string } => {
  const controller = useController(props);
  const { formId } = useValidationFormContext();

  const id = `${formId}-${convertCamelCaseToKebabCase(props.name)}`;
  return { ...controller, formId, id };
};

export { useValidationForm };
