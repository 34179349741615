import { Box } from '@mui/material';
import React, { useState, memo } from 'react';

type FlipCardProps = {
  disabled?: boolean;
  children: [React.ReactNode, React.ReactNode];
};

const FlipCard = memo(({ disabled = false, children }: FlipCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      setIsFlipped(!isFlipped);
    }
  };

  const cardStyle: React.CSSProperties = {
    perspective: '1000px',
    height: '100%',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.6s ease',
  };

  const frontStyles: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
    zIndex: isFlipped ? -1 : 1,
    transition: 'transform 0.6s ease',
  };

  const backStyles: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    transform: isFlipped ? 'rotateY(0deg)' : 'rotateY(-180deg)',
    zIndex: isFlipped ? 1 : -1,
    transition: 'transform 0.6s ease',
  };
  
  return (
    <Box sx={cardStyle} onClick={handleClick}>
      <div style={frontStyles}>{children[0]}</div>
      <div style={backStyles}>{children[1]}</div>
    </Box>
  );
});

export default FlipCard;
