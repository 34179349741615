import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import LOG from "logging/Logger";
import { Room } from "types/room";
import { ParticipationDetails } from "types/participationDetails";
import { ApiDetails } from "types/apiDetails";
import { updateRoomApi, UpdateRoomProps } from "usecase/calling/updateRoom";
import { createRoomApi } from "usecase/calling/createRoom";
import { joinRoomApi, JoinRoomProps } from "usecase/calling/joinRoom";
import { subscribeToRoomUpdatesApi } from "usecase/calling/subscribeToRoomUpdates";
import { subscribeToParticipantUpdatesApi } from "usecase/calling/subscribeToParticipantUpdates";
import { initCallApi } from "usecase/calling/initCall";
import {
  setMicrophoneStateApi,
  SetMicrophoneStateProps,
} from "usecase/calling/setMicrophoneState";
import {
  setCameraStateApi,
  SetCameraStateProps,
} from "usecase/calling/setCameraState";
import { searchContetApi } from "usecase/calling/searchContent";
import {
  updateContentApi,
  UpdateContentProps,
} from "usecase/calling/updateContent";
import setApiDetails from "usecase/calling/setApiDetails";
import setApiDetailsForEnvironment from "usecase/calling/setApiDetailsForEnvironment";
import getApiDetails from "usecase/calling/getApiDetails";
import { Environment } from "types/environment";
import getEnvironment from "usecase/calling/getEnvironment";
import { LocalParticipant, RemoteParticipant } from "types/participant";
import { ACSCallEvents } from "calling/ACSCallManager";
import { subscribeToCallEvent } from "usecase/calling/subscribeToCallEvent";
import ContentMetadataApiClient from "api/contentMetadata/ContentMetadataApiClient";
import { Camera, Microphone, Speaker } from "types/peripherals";
import { setSpeakerApi } from "usecase/calling/setSpeaker";
import { setMicrophoneApi } from "usecase/calling/setMicrophone";
import { setCameraApi } from "usecase/calling/setCamera";

export interface CallingState {
  apiEnvironment: Environment;
  apiDetails: ApiDetails;
  room: Room | undefined;
  participationDetails: ParticipationDetails | undefined;
  localVideoStream: any | undefined;
  roomCode: string;
  microphoneEnabled: boolean;
  cameraEnabled: boolean;
  roomEvents: any[];
  participantEvents: any[];
  contentMetadata: any;
  error: string | undefined;
  loading: boolean;
  callInitialised: boolean;
  searchContentResult: any[];
  localParticipant: LocalParticipant;
  remoteParticipants: RemoteParticipant[];
  wtCallSubscriptionInitialised: boolean;
  callSubscriptionInitialised: boolean;
  speakers: Speaker[];
  cameras: Camera[];
  microphones: Microphone[];
  selectedSpeaker?: Speaker;
  selectedCamera?: Camera;
  selectedMicrophone?: Microphone;
}

const initialState: CallingState = {
  apiEnvironment: getEnvironment() ?? Environment.Development,
  apiDetails: getApiDetails() ?? {},
  room: undefined,
  participationDetails: undefined,
  localVideoStream: undefined,
  roomCode: "",
  microphoneEnabled: true,
  cameraEnabled: true,
  roomEvents: [],
  participantEvents: [],
  contentMetadata: {},
  error: undefined,
  loading: false,
  callInitialised: false,
  searchContentResult: [],
  localParticipant: { cameraEnabled: true, micEnabled: true, hasView: false },
  remoteParticipants: [],
  wtCallSubscriptionInitialised: false,
  callSubscriptionInitialised: false,
  speakers: [],
  cameras: [],
  microphones: [],
};

export const createRoom = createAsyncThunk("calling/createRoom", async () => {
  return await createRoomApi();
});

export const joinRoom = createAsyncThunk(
  "calling/joinRoom",
  async (joinRoomProps: JoinRoomProps) => {
    return await joinRoomApi(joinRoomProps);
  }
);

export const initCall = createAsyncThunk(
  "calling/initCall",
  async (initCallProps: any) => {
    return initCallApi();
  }
);

export const setMicrophoneState = createAsyncThunk(
  "calling/setMicrophoneState",
  async (setMicrophoneStateProps: SetMicrophoneStateProps) => {
    return setMicrophoneStateApi(setMicrophoneStateProps);
  }
);

export const setCameraState = createAsyncThunk(
  "calling/setCameraState",
  async (setCameraStateProps: SetCameraStateProps) => {
    return setCameraStateApi(setCameraStateProps);
  }
);

export const getMetadata = createAsyncThunk(
  "calling/getMetadata",
  async (room: Room) => {
    if (room.entityId != null) {
      return await ContentMetadataApiClient.getInstance().getMetadata(
        room.entityId
      );
    } else {
      return {};
    }
  }
);

export const updateRoom = createAsyncThunk(
  "calling/updateRoom",
  async (updateRoomParams: UpdateRoomProps) => {
    return updateRoomApi(updateRoomParams);
  }
);

export const updateContent = createAsyncThunk(
  "calling/updateContent",
  async (updateContentParams: UpdateContentProps, { rejectWithValue }) => {
    try {
      return updateContentApi(updateContentParams);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const searchContent = createAsyncThunk(
  "calling/searchContent",
  async (text: string) => {
    return searchContetApi(text);
  }
);

export const setGraphQLEndpoint = createAsyncThunk(
  "calling/setGraphQLEndpoint",
  async (endpoint: string) => {
    setApiDetails({ graphQLEndpoint: endpoint });
    return endpoint;
  }
);

export const setRealtimeGraphQLEndpoint = createAsyncThunk(
  "calling/setRealtimeGraphQLEndpoint",
  async (endpoint: string) => {
    setApiDetails({ realtimeGraphQLEndpoint: endpoint });
    return endpoint;
  }
);

export const setApiKey = createAsyncThunk(
  "calling/setApiKey",
  async (apiKey: string) => {
    setApiDetails({ apiKey: apiKey });
    return apiKey;
  }
);

export const setEnvironmentSettings = createAsyncThunk(
  "calling/setEnvironmentSettings",
  async (enviornemnt: string) => {
    setApiDetailsForEnvironment(enviornemnt);
    return enviornemnt;
  }
);

export const addLocalView = createAsyncThunk(
  "calling/addLocalView",
  async () => {
    return true;
  }
);

export const addRemoteParticipant = createAsyncThunk(
  "calling/addRemoteParticipant",
  async (uuid: string) => {
    return uuid;
  }
);

export const removeRemoteParticipant = createAsyncThunk(
  "calling/removeRemoteParticipant",
  async (uuid: string) => {
    return uuid;
  }
);

export const addRemoteParticipantView = createAsyncThunk(
  "calling/addRemoteParticipantView",
  async (uuid: string) => {
    return uuid;
  }
);

export const removeRemoteParticipantView = createAsyncThunk(
  "calling/removeRemoteParticipantView",
  async (uuid: string) => {
    return uuid;
  }
);

export const setSpeaker = createAsyncThunk(
  "calling/setSpeaker",
  async (speaker: Speaker) => {
    const result = await setSpeakerApi({ speaker });
    return { speaker: speaker, result: result };
  }
);

export const setMicrophone = createAsyncThunk(
  "calling/setMicrophone",
  async (microphone: Microphone) => {
    const result = await setMicrophoneApi({ microphone });
    return { microphone: microphone, result: result };
  }
);

export const setCamera = createAsyncThunk(
  "calling/setCamera",
  async (camera: Camera) => {
    const result = await setCameraApi({ camera });
    return { camera: camera, result: result };
  }
);

export const subscribeToWTCall = createAsyncThunk(
  "calling/subscribeToWTCall",
  async (_, { dispatch }) => {
    subscribeToCallEvent(ACSCallEvents.LocalParticpantViewAvailable, (args) => {
      dispatch(addLocalView());
    });
    subscribeToCallEvent(ACSCallEvents.RemoteParticipantAdded, (uuid) => {
      dispatch(addRemoteParticipant(uuid));
    });
    subscribeToCallEvent(ACSCallEvents.RemoteParticipantRemoved, (uuid) => {
      dispatch(removeRemoteParticipant(uuid));
    });
    subscribeToCallEvent(ACSCallEvents.RemoteParticipantViewAdded, (uuid) => {
      dispatch(addRemoteParticipantView(uuid));
    });
    subscribeToCallEvent(ACSCallEvents.RemoteParticipantViewRemoved, (uuid) => {
      dispatch(removeRemoteParticipantView(uuid));
    });
    subscribeToCallEvent(ACSCallEvents.SpeakersAvailable, (speakers) => {
      dispatch(setSpeakers(speakers));
    });
    subscribeToCallEvent(ACSCallEvents.MicrophonesAvailable, (microphones) => {
      dispatch(setMicrophones(microphones));
    });
    subscribeToCallEvent(ACSCallEvents.CamerasAvailable, (cameras) => {
      dispatch(setCameras(cameras));
    });

    return true;
  }
);

export const subscribeToCallEvents = createAsyncThunk(
  "calling/subscribeToCallEvents",
  async (roomCode: string, { dispatch }) => {
    await subscribeToRoomUpdatesApi({
      roomCode: roomCode,
      callback: (event: string) => {
        dispatch(addRoomEvent(event));
      },
    });

    await subscribeToParticipantUpdatesApi({
      roomCode: roomCode,
      callback: (event: string) => {
        dispatch(addParticipantEvent(event));
      },
    });
    return true;
  }
);

export const callingSlice = createSlice({
  name: "calling",
  initialState,
  reducers: {
    setRoomCode(state, action: PayloadAction<string>) {
      state.roomCode = action.payload;
    },
    handleRoomEvent(state, action: PayloadAction<any>) {
      LOG.info("Received action: " + JSON.stringify(action.payload));
      const row = action.payload.data.onUpdateRoom;

      row.time = new Date().getTime();
      LOG.info("Add room event: " + JSON.stringify(row));
      state.roomEvents.push(row);

      const previousRoomState = state.room;
      const room = { previousRoomState, ...row };
      state.room = room;
    },

    handleParticipantEvent(state, action: PayloadAction<any>) {
      LOG.info("Received action: " + JSON.stringify(action.payload));

      const params = action.payload;
      const row = params.data.onUpdateParticipant;
      row.time = new Date().getTime();
      LOG.info("Add participant event: " + JSON.stringify(row));
      state.participantEvents.push(row);

      if (params && params.data && params.data.onUpdateParticipant) {
        const uuid = params.data.onUpdateParticipant.azureUserId;
        const cameraHardwareEnabled =
          params.data.onUpdateParticipant.cameraHardwareEnabled;
        const cameraEnabled = cameraHardwareEnabled
          ? params.data.onUpdateParticipant.cameraEnabled
          : false;
        const micEnabled = cameraHardwareEnabled
          ? params.data.onUpdateParticipant.micEnabled
          : false;

        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].cameraEnabled = cameraEnabled;
          result[0].micEnabled = micEnabled;
          state.remoteParticipants = [...state.remoteParticipants];
        }

        if (uuid === state.participationDetails?.participant.azureUserId) {
          state.localParticipant.cameraEnabled = cameraEnabled;
          state.localParticipant.micEnabled = micEnabled;
        }
      }
    },
    clearError(state, action: PayloadAction<any>) {
      state.error = undefined;
    },
    setSpeakers(state, action: PayloadAction<Array<Speaker>>) {
      state.speakers = action.payload;
      if (action.payload.length > 0) {
        state.selectedSpeaker = action.payload[0];
      }
    },
    setMicrophones(state, action: PayloadAction<Array<Microphone>>) {
      state.microphones = action.payload;
      if (action.payload.length > 0) {
        state.selectedMicrophone = action.payload[0];
      }
    },
    setCameras(state, action: PayloadAction<Array<Camera>>) {
      state.cameras = action.payload;
      if (action.payload.length > 0) {
        state.selectedCamera = action.payload[0];
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(setGraphQLEndpoint.fulfilled, (state, action) => {
        state.apiDetails = getApiDetails() ?? {};
      })
      .addCase(setRealtimeGraphQLEndpoint.fulfilled, (state, action) => {
        state.apiDetails = getApiDetails() ?? {};
      })
      .addCase(setApiKey.fulfilled, (state, action) => {
        state.apiDetails = getApiDetails() ?? {};
      })
      .addCase(setEnvironmentSettings.fulfilled, (state, action) => {
        state.apiDetails = getApiDetails() ?? {};
        state.apiEnvironment = getEnvironment();
      })
      .addCase(createRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        state.room = action.payload;
        state.loading = false;
      })
      .addCase(createRoom.rejected, (state, action) => {
        state.error = "Failed to create room.";
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(joinRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(joinRoom.fulfilled, (state, action) => {
        state.participationDetails = action.payload.participantDetails;
        if (action.payload.room) {
          state.room = action.payload.room;
        }
        state.loading = false;
      })
      .addCase(joinRoom.rejected, (state, action) => {
        state.error = "Failed to join room.";
        state.loading = false;
        LOG.error(action.error);
      })

      .addCase(initCall.pending, (state) => {
        state.loading = true;
      })
      .addCase(initCall.fulfilled, (state, action) => {
        state.loading = false;
        state.callInitialised = true;
      })
      .addCase(initCall.rejected, (state, action) => {
        state.error = "Failed to initialise call.";
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(setMicrophoneState.pending, (state) => {
        state.loading = true;
      })
      .addCase(setMicrophoneState.fulfilled, (state, action) => {
        state.microphoneEnabled = action.payload;
        state.loading = false;
      })
      .addCase(setMicrophoneState.rejected, (state, action) => {
        state.error = "Failed to set microphone state.";
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(setCameraState.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCameraState.fulfilled, (state, action) => {
        state.cameraEnabled = action.payload;
        state.loading = false;
      })
      .addCase(setCameraState.rejected, (state, action) => {
        state.error = "Failed to set camera state.";
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(getMetadata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.contentMetadata = action.payload;
        state.loading = false;
      })
      .addCase(getMetadata.rejected, (state, action) => {
        state.error = "Failed to get metadata.";
        state.loading = false;
        LOG.error(action.error);
      })

      .addCase(updateRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRoom.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateRoom.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to update room. " + errorMessage;
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(subscribeToCallEvents.pending, (state) => {})
      .addCase(subscribeToCallEvents.fulfilled, (state) => {
        state.callSubscriptionInitialised = true;
      })
      .addCase(subscribeToCallEvents.rejected, (state, action) => {
        state.error = "Failed to subscribe call events.";
        LOG.error(action.error);
      })

      .addCase(searchContent.pending, (state) => {})
      .addCase(searchContent.fulfilled, (state, action) => {
        state.searchContentResult = action.payload;
      })
      .addCase(searchContent.rejected, (state, action) => {
        state.error = "Failed to search content.";
        LOG.error(action.error);
      })
      .addCase(updateContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateContent.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to update content. " + errorMessage;
        state.loading = false;
        LOG.error(action.error);
      })
      .addCase(addRemoteParticipant.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        const roomParticipantResult = state.room?.participants?.items!.filter(
          (participant: any) => {
            return participant.azureUserId === uuid;
          }
        );

        var cameraEnabled = true;
        var micEnabled = true;

        if (roomParticipantResult && roomParticipantResult.length > 0) {
          const roomParticipant = roomParticipantResult[0];
          cameraEnabled = roomParticipant.cameraHardwareEnabled
            ? roomParticipant.cameraEnabled
            : false;
          micEnabled = roomParticipant?.cameraHardwareEnabled
            ? roomParticipant.micEnabled
            : false;
        }

        if (result.length === 0) {
          state.remoteParticipants.push({
            uuid: uuid,
            hasView: false,
            cameraEnabled: cameraEnabled,
            micEnabled: micEnabled,
          });
        }
      })

      .addCase(removeRemoteParticipant.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          state.remoteParticipants.splice(
            state.remoteParticipants.indexOf(result[0]),
            1
          );
        }
      })

      .addCase(addRemoteParticipantView.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].hasView = true;
          state.remoteParticipants = [...state.remoteParticipants];
        }
      })

      .addCase(removeRemoteParticipantView.fulfilled, (state, action) => {
        const uuid = action.payload;
        const result = state.remoteParticipants.filter((participant) => {
          return participant.uuid === uuid;
        });
        if (result.length > 0) {
          result[0].hasView = false;
          state.remoteParticipants = [...state.remoteParticipants];
        }
      })

      .addCase(addLocalView.fulfilled, (state, action) => {
        state.localParticipant.hasView = true;
      })

      .addCase(subscribeToWTCall.fulfilled, (state, action) => {
        state.wtCallSubscriptionInitialised = true;
      })

      .addCase(setCamera.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCamera.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.result) {
          state.error = "Failed to change camera";
        } else {
          state.selectedCamera = action.payload.camera;
        }
      })
      .addCase(setCamera.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to change camera " + errorMessage;
        state.loading = false;
        LOG.error(action.error);
      })

      .addCase(setMicrophone.pending, (state) => {
        state.loading = true;
      })
      .addCase(setMicrophone.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.result) {
          state.error = "Failed to change microphone";
        } else {
          state.selectedMicrophone = action.payload.microphone;
        }
      })
      .addCase(setMicrophone.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to change microphone " + errorMessage;
        state.loading = false;
        LOG.error(action.error);
      })

      .addCase(setSpeaker.pending, (state) => {
        state.loading = true;
      })
      .addCase(setSpeaker.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload.result) {
          state.error = "Failed to change speaker";
        } else {
          state.selectedSpeaker = action.payload.speaker;
        }
      })
      .addCase(setSpeaker.rejected, (state, action) => {
        const errorMessage = action.error.message ?? "";
        state.error = "Failed to change speaker " + errorMessage;
        state.loading = false;
        LOG.error(action.error);
      });
  },
});

export const {
  setRoomCode,
  handleRoomEvent: addRoomEvent,
  handleParticipantEvent: addParticipantEvent,
  clearError,
  setSpeakers,
  setCameras,
  setMicrophones,
} = callingSlice.actions;
export const selectCallingState = (state: RootState) => state.calling;

export default callingSlice.reducer;
