import { createAuthenticatedHttpLink } from "api/util/authenticatedHttpLink";
import { createSplitLink } from "api/util/splitLink";
import { createWebSocketLink } from "api/util/webSocketLink";
import {
  ApolloClient,
  DefaultOptions,
  gql,
  InMemoryCache,
  isApolloError,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  createEnvironmentMutation,
  createFeatureMutation,
  deleteEnvironmentMutation,
  deleteFeatureMutation,
  getEnvironmentsQuery,
  getFeaturesQuery,
  updateEnvironmentMutation,
  updateFeatureMutation,
} from "./queries";
import {
  ApiError,
  GraphQLError,
  NetworkError,
  UnauthorisedError,
} from "../ApiErrors";
import {
  CreateFeatureInput,
  DeleteFeatureInput,
  UpdateFeatureInput,
} from "types/featureFlags";
import {
  CreateFeatureEnvironmentInput,
  DeleteFeatureEnvironmentInput,
  GetFeaturesInput,
  UpdateFeatureEnvironmentInput,
} from "types/featureEnvironments";
import { getCognitoIdToken } from "usecase/auth/getCognitoIdToken";
import { createAuthorizationHeader } from "api/util/authorizationAuthHeader";

const {
  REACT_APP_REMOTE_MANAGEMENT_API_GRAPHQL_URL,
  REACT_APP_REMOTE_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
} = process.env;

class RemoteManagementApiClient {
  private static instance: RemoteManagementApiClient;

  private apolloClient?: ApolloClient<NormalizedCacheObject>;

  public static getInstance(): RemoteManagementApiClient {
    if (RemoteManagementApiClient.instance != null) {
      return this.instance;
    }
    RemoteManagementApiClient.instance = new RemoteManagementApiClient();
    return RemoteManagementApiClient.instance;
  }

  private constructor() {
    this._setup();
  }

  async _setup() {
    if (
      !REACT_APP_REMOTE_MANAGEMENT_API_GRAPHQL_URL ||
      !REACT_APP_REMOTE_MANAGEMENT_API_REALTIME_GRAPHQL_URL
    ) {
      throw new Error("Invalid remote management API details");
    }
    const authHeader = createAuthorizationHeader(
      REACT_APP_REMOTE_MANAGEMENT_API_GRAPHQL_URL,
      await getCognitoIdToken()
    );

    const httpLink = createAuthenticatedHttpLink(
      REACT_APP_REMOTE_MANAGEMENT_API_GRAPHQL_URL,
      authHeader
    );

    const wsLink = createWebSocketLink(
      REACT_APP_REMOTE_MANAGEMENT_API_REALTIME_GRAPHQL_URL,
      authHeader
    );

    const splitLink = createSplitLink(httpLink, wsLink);

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };

    this.apolloClient = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });
  }

  async getEnvironments() {
    let result;
    try {
      result = await this.apolloClient?.query({
        query: gql(getEnvironmentsQuery),
      });
    } catch (e) {
      this.handleError(e);
      return null;
    }

    if (!result || !result.data) {
      throw new ApiError("No data returned for getEnvironments");
    }

    return result.data;
  }

  async createEnvironment(input: CreateFeatureEnvironmentInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(createEnvironmentMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for createEnvironment");
    }

    return result.data;
  }

  async updateEnvironment(input: UpdateFeatureEnvironmentInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(updateEnvironmentMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for updateEnvironment");
    }

    return result.data;
  }

  async deleteEnvironment(input: DeleteFeatureEnvironmentInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(deleteEnvironmentMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for deleteEnvironment");
    }

    return result.data;
  }

  async getFeatures(input: GetFeaturesInput) {
    let result;
    try {
      result = await this.apolloClient?.query({
        query: gql(getFeaturesQuery),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return null;
    }

    if (!result || !result.data) {
      throw new ApiError("No data returned for getFeatures");
    }

    return result.data;
  }

  async createFeature(input: CreateFeatureInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(createFeatureMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for createFeature");
    }

    return result.data;
  }

  async updateFeature(input: UpdateFeatureInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(updateFeatureMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for updateFeature");
    }

    return result.data;
  }

  async deleteFeature(input: DeleteFeatureInput) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(deleteFeatureMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for deleteFeature");
    }

    return result.data;
  }

  handleError(e: any) {
    if (!isApolloError(e)) {
      throw new Error();
    }

    if (this.isUnauthorised(e)) {
      throw new UnauthorisedError(e?.networkError?.message);
    }

    if (this.isGraphQLError(e)) {
      throw new GraphQLError(e.graphQLErrors[0].message);
    }

    if (e.networkError) {
      throw new NetworkError(e.networkError.message);
    }

    throw new ApiError(e.message);
  }

  /**
   * Return true if the error represents an unauthorised error.
   *
   * @param {ApolloError} e
   * @returns True if the error is a network error and status code is 4XX
   */
  isUnauthorised(e: any) {
    return (
      e.networkError &&
      e.networkError.statusCode / 100 === 4 &&
      e.networkError.message
    );
  }

  /**
   * Return true if the error contains any graphQL errors.
   *
   * @param {ApolloError} e
   * @returns True if error contains any graphQL errors
   */
  hasGraphQLError(e: any) {
    return (
      e.graphQLErrors &&
      e.graphQLErrors.length > 0 &&
      e.graphQLErrors[0].message
    );
  }

  isGraphQLError(e: any) {
    return (
      e.graphQLErrors &&
      e.graphQLErrors.length > 0 &&
      e.graphQLErrors[0].message
    );
  }
}

export default RemoteManagementApiClient;
