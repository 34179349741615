import { FormControl } from "@mui/material";
import { ParticipationDetails } from "types/participationDetails";
import { CopyTextfield } from "./CopyTextfield";

interface ParticipationDetailsFormProps {
  participationDetails: ParticipationDetails;
}

const ParticipationDetailsForm = ({
  participationDetails,
}: ParticipationDetailsFormProps) => (
  <FormControl fullWidth>
    <CopyTextfield label="Token" value={participationDetails.token} />
    <CopyTextfield
      label="Participant ID"
      value={participationDetails.participant.id}
    />
    <CopyTextfield
      label="Participant Azure User ID"
      value={participationDetails.participant.azureUserId}
    />
    <CopyTextfield
      label="Participant Camera Hardware Enabled"
      value={participationDetails.participant.cameraHardwareEnabled}
    />
    <CopyTextfield
      label="Participant Camera Enabled"
      value={participationDetails.participant.cameraEnabled}
    />
    <CopyTextfield
      label="Participant Microphone Enabled"
      value={participationDetails.participant.micEnabled}
    />
  </FormControl>
);

export default ParticipationDetailsForm;
