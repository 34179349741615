import { Fragment, memo, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import SectionCard from 'components/SectionCard';
import FlipCard from 'components/FlipCard';
import { DateTimeFormatOptions } from 'luxon';

const HomePage = memo(() => {
  const options: DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const date = new Date().toLocaleDateString('eu-GB', options);
  const [day] = useState(new Date().getDay());
  const [frontText, setFrontText] = useState('');

  const [disabled, setDisabled] = useState(true);
  const [showDate, setShowDate] = useState(false);

  useEffect(() => {
    switch (day) {
      case 0 || 6:
        setFrontText("It's the weekend - go enjoy it!");
        break;
      case 5:
        setFrontText('Guess what...');
        setDisabled(false);
        break;
      case 4:
        setShowDate(true);
        setFrontText("It's nearly Friday");
        break;
      default:
        setShowDate(true);
        break;
    }
  }, [day]);

  const back = "It's Friday!";

  return (
    <Fragment>
      <SectionCard>
        <Typography align="center" variant="h5">
          Welcome to Watch Together Web
        </Typography>
      </SectionCard>
      <FlipCard disabled={disabled}>
        <SectionCard>
          <Typography align="center" variant="h5">
            {frontText}
          </Typography>
          {showDate && (
            <Typography align="center" variant="h6">
              {date}
            </Typography>
          )}
        </SectionCard>
        <SectionCard>
          <Typography align="center" variant="h3">
            {back}
          </Typography>
        </SectionCard>
      </FlipCard>
    </Fragment>
  );
});

export default HomePage;
