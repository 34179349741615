import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";

import { useState, useEffect } from "react";
import {
  FeatureEnvironment,
} from "types/featureEnvironments";

interface FeatureEnvironmentsDataGridProps {
  environments: FeatureEnvironment[];
  handleEdit: (environment: FeatureEnvironment) => void;
  handleDelete: (environment: FeatureEnvironment) => void;
}

export default function FeatureEnvironmentsDataGrid({
  environments,
  handleEdit,
  handleDelete,
}: FeatureEnvironmentsDataGridProps) {
  const [rows, setRows] = useState(environments as GridRowsProp);

  useEffect(() => {
    setRows(environments as GridRowsProp);
  }, [environments]);

  const handleEditClick = (id: GridRowId) => () => {
    const env = environments.filter((env) => env.id === id);
    if (env && env.length > 0) {
      handleEdit(env[0]);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const env = environments.filter((env) => env.id === id);
    if (env && env.length > 0) {
      handleDelete(env[0]);
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "cognitoGroupName",
      headerName: "Cognito Group Name",
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 200,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      id="feature-environments-data-grid"
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid rows={rows} columns={columns} />
    </Box>
  );
}
