import { ApiClient } from "api/roomManagement/RoomManagementApiClient";
import { Room } from "types/room";
import { getRoomApi } from "./getRoom";
import { getNickName } from "./getNickname";
import { getSerialNumber } from "./getSerialNumber";
import { ParticipationManager } from "calling/ParticipationManager";
import { EnvironmentManager } from "calling/EnvironmentManager";

export interface JoinRoomProps {
  roomCode: string;
  loadRoom: boolean;
}
export async function joinRoomApi(joinRoomProps: JoinRoomProps) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }

  const apiClient = ApiClient.getInstance(apiDetails!);
  const participationManager = ParticipationManager.getInstance();

  const nickname = getNickName();
  const serialNumber = getSerialNumber();

  const cameraHardwareEnabled = true;
  const micEnabled = true;
  const cameraEnabled = true;

  const input = {
    nickname,
    roomCode: joinRoomProps.roomCode,
    serialNumber: serialNumber,
    cameraHardwareEnabled,
    micEnabled,
    cameraEnabled,
  };
  let participantDetails = await apiClient.joinRoom(input);

  participationManager.setParticipationDetails(participantDetails);

  let room: Room | undefined;
  if (joinRoomProps.loadRoom) {
    room = await getRoomApi({
      roomCode: joinRoomProps.roomCode,
    });
    participationManager.setRoom(room);
  }
  return { participantDetails: participantDetails, room: room };
}
