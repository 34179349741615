import { ThemeOptions } from '@mui/material';
import { amber, green, red } from '@mui/material/colors';

const panelColour = '#1d1f4c';
const lightColour = '#0d1e83';
const backgroundColour = '#060739';
const background = `linear-gradient(270deg, ${lightColour} 0%, ${backgroundColour} 100%)`;
const border = '1px solid rgba(81, 81, 81, 1)';
const transparentColour = 'rgba(0, 0, 0, 0)';

const rainbow =
  'linear-gradient(to right, rgb(255, 158, 0) 0%, rgb(255, 0, 0) 25%, rgb(181, 0, 125) 50%, rgb(33, 66, 156) 75%, rgb(0, 113, 255) 100%)';
const rainbowRepeat =
  'linear-gradient(to right, rgb(255, 158, 0) 0%, rgb(255, 0, 0) 12%, rgb(181, 0, 125) 25%, rgb(33, 66, 156) 37%, rgb(0, 113, 255) 50%, rgb(33, 66, 156) 62%, rgb(181, 0, 125) 75%, rgb(255, 0, 0) 87%, rgb(255, 158, 0) 100% )';

export const glass: ThemeOptions = {
  typography: {
    fontSize: 16,
    fontFamily: 'Sky Text',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#6590ff',
      light: lightColour,
      dark: backgroundColour,
    },
    secondary: {
      main: '#ff387a',
    },
    error: {
      main: '#ff387a',
    },
    background: {
      default: panelColour,
      paper: 'rgba(241, 241, 241, 0.06)',
    },
    action: {
      disabled: '#a6acd0',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: background,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: border,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          background: green[500],
          color: 'black',
        },
        filledError: {
          backgroundColor: red[500],
          color: 'white',
        },
        filledWarning: {
          backgroundColor: amber[500],
          color: 'black',
        },
        filledInfo: {
          background: '#fff',
          color: 'black',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: background,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          border: border,
          background: panelColour,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiChip: {
      defaultProps: {
        sx: { fontWeight: '600' },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: border,
          background: panelColour,
        },
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        sx: {
          stroke: rainbow,
          background: rainbow,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        sx: {
          '& .MuiPaper-root': {
            border: border,
            background: panelColour,
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          background: panelColour,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
      },
      styleOverrides: {
        indicator: {
          height: 5,
          backgroundSize: '400px',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundImage: rainbowRepeat,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
        popper: {
          background: transparentColour,
        },
      },
    },
  },
};
