import { memo, useCallback, useMemo, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { NavigationItem } from '../data/navigation';
import LOG from "../logging/Logger";
import { TabsProvider } from 'components/tabs/TabsProvider';
import AppBar from 'components/navigation/AppBar';
import Drawer from 'components/navigation/Drawer';
import { navigationTabPath } from "util/paths";
import { useNavigate } from 'react-router-dom';

const getTabLabels = (navigation: NavigationItem[]): string[] => {
  if (!navigation) {
    return [];
  }

  return Array.from(
    new Set(navigation.filter((item) => item.tab?.length).map((item) => item.tab) as string[])
  );
};

interface NavigationProps {
  isCallingBackend: boolean;
  selectedItemId: string | undefined;
  selectedItemTab: string | undefined;
  navigation: NavigationItem[];
  onSelect(item: NavigationItem): void;
  logout(): void;
}

const Navigation = memo(
  ({
    isCallingBackend,
    selectedItemId,
    selectedItemTab,
    navigation,
    onSelect,
    logout,
  }: NavigationProps) => {
    LOG.debug('Rendering: Navigation');

    const navigate = useNavigate();

    const [openDrawerOnMobile, setOpenDrawerOnMobile] = useState(false);
    const tabs = useMemo(
      () => getTabLabels(navigation).filter((tab) => tab),
      [navigation]
    );

    const handleToggleDrawerOnMobile = useCallback(() => {
      setOpenDrawerOnMobile((previous) => !previous);
    }, []);

    const handleTabChange = useCallback(
      (newTab: string) => {
        navigate(navigationTabPath(newTab));
      },
      [navigate]
    );

    const reducedSpace = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
      <TabsProvider tabs={tabs} onChangeTab={handleTabChange} initialTab={selectedItemTab}>
        <AppBar
          smallScreen={reducedSpace}
          isCallingBackend={isCallingBackend}
          onToggleDrawer={handleToggleDrawerOnMobile}
          onLogout={logout}
        />
        <Drawer
          smallScreen={reducedSpace}
          navigation={navigation}
          selectedItemId={selectedItemId}
          onSelect={onSelect}
          onToggleDrawer={handleToggleDrawerOnMobile}
          openDrawer={openDrawerOnMobile}
        />
      </TabsProvider>
    );
  }
);

export default Navigation;
