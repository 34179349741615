import { useCallback, useState } from 'react';

interface UseTabsProps {
  defaultValue?: number;
  onChange?(value: number): void;
}

type UseTabsRegisterFn = (props?: { onChange?: (e: any, value: number) => void }) => {
  onChange: (e: any, value: number) => void;
  value: number;
};

export const useSimpleTabs = (props?: UseTabsProps) => {
  const [value, setValue] = useState(props?.defaultValue ?? 0);

  const handleChange = useCallback(
    (e: any, value: number) => {
      setValue(value);
      if (props?.onChange) {
        props.onChange(value);
      }
    },
    [setValue, props]
  );

  const register: UseTabsRegisterFn = ({ onChange } = {}) => {
    return {
      value,
      onChange: (e, value) => {
        onChange && onChange(e, value);
        handleChange(e, value);
      },
    };
  };

  return {
    value,
    setValue,
    register: useCallback(register, [value, handleChange]),
  };
};
