import FeatureFlagsPage from "pages/featureflags/FeatureFlagsPage";
import CallingPage from "pages/calling/CallingPage";
import FeatureEnvironmentsPage from "pages/featureEnvironments/FeatureEnvironmentsPage";
import ContactPage from "pages/contact/ContactPage";
import HomePage from "pages/home/HomePage";

export interface NavigationItem {
  id: string;
  title: string;
  paths: string[];
  defaultPath?: string;
  group?: string;
  component: any;
  sidebar?: boolean;
  icon?: JSX.Element;
  tab?: string;
}

export const navigation: NavigationItem[] = [
  {
    id: "home",
    title: "Home",
    paths: ["/"],
    component: HomePage,
    sidebar: true,
  },
  {
    id: "calling",
    title: "Calling",
    paths: ["/calling"],
    component: CallingPage,
    sidebar: true,
  },
  {
    id: "featureFlags",
    title: "Feature Flags",
    paths: ["/featureFlags"],
    component: FeatureFlagsPage,
    sidebar: true,
    group: "FeatureFlags",
  },
  {
    id: "featureEnvironments",
    title: "Feature Environments",
    paths: ["/featureEnvironments"],
    component: FeatureEnvironmentsPage,
    sidebar: true,
    group: "FeatureFlags",
  },
  {
    id: "contact",
    title: "Contact",
    paths: ["/contact"],
    component: ContactPage,
    sidebar: true,
  },
];
