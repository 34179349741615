import LOG from "../logging/Logger";
import { ParticipationDetails } from "../types/participationDetails";
import { Room } from "../types/room";
import { EventEmitter } from "eventemitter3";
import { Speaker, Microphone, Camera } from "../types/peripherals";

const {
  CallClient,
  VideoStreamRenderer,
  LocalVideoStream,
} = require("@azure/communication-calling");
const {
  AzureCommunicationTokenCredential,
} = require("@azure/communication-common");

export enum ACSCallEvents {
  RemoteParticipantAdded = "RemoteParticipantAdded",
  RemoteParticipantRemoved = "RemoteParticipantRemoved",
  RemoteParticipantViewAdded = "RemoteParticipantViewAdded",
  RemoteParticipantViewRemoved = "RemoteParticipantViewRemoved",
  LocalParticpantViewAvailable = "LocalParticpantViewAvailable",
  SpeakersAvailable = "SpeakersAvailable",
  MicrophonesAvailable = "MicrophonesAvailable",
  CamerasAvailable = "CamerasAvailable",
}

const PERIPHERALS_CHECK_DELAY_MS = 500;

class ACSCallManager extends EventEmitter {
  private static instance: ACSCallManager;
  private callClient: any | null;
  private callAgent: any | null;
  private deviceManager: any | null;
  private call: any | null;
  private localVideoStream: any | null;
  private localVideoStreamRenderer: any | null;
  private participants = new Map();
  private participantsViews = new Map();

  public static getInstance(): ACSCallManager {
    if (!ACSCallManager.instance) {
      ACSCallManager.instance = new ACSCallManager();
    }

    return ACSCallManager.instance;
  }

  async init(room: Room, participationDetails: ParticipationDetails) {
    LOG.info("Init WTCallClient");
    this.callClient = new CallClient();
    const authToken = participationDetails.token.trim();
    const tokenCredential = new AzureCommunicationTokenCredential(authToken);

    this.callAgent = await this.callClient.createCallAgent(tokenCredential);
    await this.initPeripherials();
    // Listen for an incoming call to accept.
    this.callAgent.on("incomingCall", async (args: any) => {
      try {
        //const incomingCall = args.incomingCall;
        // acceptCallButton.disabled = false;
        // startCallButton.disabled = true;
      } catch (error) {
        console.error(error);
      }
    });
    this.joinCall(room.vcId);
  }

  private async initPeripherials() {
    this.deviceManager = await this.callClient.getDeviceManager();
    await this.deviceManager.askDevicePermission({ video: true, audio: true });

    setTimeout(() => {
      this._initialiseSpeakers();
      this._initialiseMicrophones();
      this._initialiseCameras();
    }, PERIPHERALS_CHECK_DELAY_MS);
  }

  private async _initialiseSpeakers() {
    try {
      const deviceSpeakers = await this.deviceManager.getSpeakers();

      const speakers = (deviceSpeakers as Array<any>)
        .filter((speaker) => speaker.name.length > 0)
        .map((speaker) => {
          return { id: speaker.id, name: speaker.name };
        });
      this.emit(ACSCallEvents.SpeakersAvailable, speakers);
    } catch (error) {
      LOG.error(error);
    }
  }

  private async _initialiseMicrophones() {
    try {
      const deviceMicrophones = await this.deviceManager.getMicrophones();
      const microphones = (deviceMicrophones as Array<any>)
        .filter((microphone) => microphone.name.length > 0)
        .map((microphone) => {
          return { id: microphone.id, name: microphone.name };
        });
      this.emit(ACSCallEvents.MicrophonesAvailable, microphones);
    } catch (error) {
      LOG.error(error);
    }
  }

  private async _initialiseCameras() {
    try {
      const deviceCameras = await this.deviceManager.getCameras();
      const cameras = (deviceCameras as Array<any>)
        .filter((camera) => camera.name.length > 0)
        .map((camera) => {
          return { id: camera.id, name: camera.name };
        });
      this.emit(ACSCallEvents.CamerasAvailable, cameras);
    } catch (error) {
      LOG.error(error);
    }
  }

  public async setSpeaker(selectedSpeaker: Speaker) {
    try {
      const deviceSpeakers = await this.deviceManager.getSpeakers();
      const speaker = (deviceSpeakers as Array<any>).find(
        (speaker) => speaker.id === selectedSpeaker.id
      );
      await this.deviceManager.selectSpeaker(speaker);
      return true;
    } catch (error) {
      LOG.error(error);
      return false;
    }
  }

  public async setMicrophone(selectedMicrophone: Microphone) {
    try {
      const deviceMicrophones = await this.deviceManager.getMicrophones();
      const microphone = (deviceMicrophones as Array<any>).find(
        (microphone) => microphone.id === selectedMicrophone.id
      );
      await this.deviceManager.selectMicrophone(microphone);
      return true;
    } catch (error) {
      LOG.error(error);
      return false;
    }
  }

  public async setCamera(selectedCamera: Camera) {
    try {
      const deviceCameras = await this.deviceManager.getCameras();
      const camera = (deviceCameras as Array<any>).find(
        (camera) => camera.id === selectedCamera.id
      );

      this.localVideoStream.switchSource(camera);
      LOG.info(`Selected camera: ${camera}`);
      return true;
    } catch (error) {
      LOG.error(error);
      return false;
    }
  }

  private async joinCall(groupId: string) {
    try {
      this.localVideoStream = await this.createLocalVideoStream();
      const videoOptions = this.localVideoStream
        ? { localVideoStreams: [this.localVideoStream] }
        : undefined;
      //this.displayLocalVideoStream();
      LOG.info("Join call...");
      this.call = this.callAgent.join({ groupId }, { videoOptions });

      this.call.localVideoStreams.forEach(async (lvs: any) => {
        this.localVideoStream = lvs;
        await this.displayLocalVideoStream();
      });
      this.call.on("localVideoStreamsUpdated", (e: any) => {
        e.added.forEach(async (lvs: any) => {
          this.localVideoStream = lvs;
          await this.displayLocalVideoStream();
        });
        e.removed.forEach((lvs: any) => {
          this.removeLocalVideoStream();
        });
      });

      this.call.remoteParticipants.forEach((remoteParticipant: any) => {
        this.subscribeToRemoteParticipant(remoteParticipant);
      });
      this.call.on("remoteParticipantsUpdated", (e: any) => {
        // Subscribe to new remote participants that are added to the call.
        e.added.forEach((remoteParticipant: any) => {
          this.subscribeToRemoteParticipant(remoteParticipant);
        });
        // Unsubscribe from participants that are removed from the call
        e.removed.forEach((remoteParticipant: any) => {
          let uuid = remoteParticipant.identifier.communicationUserId;
          this.participants.delete(uuid);
          this.emit(ACSCallEvents.RemoteParticipantRemoved, uuid);
        });
      });

      LOG.info("Joined call!");
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * To render a LocalVideoStream, you need to create a new instance of VideoStreamRenderer, and then
   * create a new VideoStreamRendererView instance using the asynchronous createView() method.
   * You may then attach view.target to any UI element.
   */
  private async createLocalVideoStream() {
    const camera = (await this.deviceManager.getCameras())[0];
    if (camera) {
      return new LocalVideoStream(camera);
    } else {
      console.error(`No camera device found on the system`);
      return null;
    }
  }

  private async displayLocalVideoStream() {
    LOG.info("Display local video stream.");
    try {
      this.localVideoStreamRenderer = new VideoStreamRenderer(
        this.localVideoStream
      );
      const view = await this.localVideoStreamRenderer.createView({
        scalingMode: "Crop",
      });
      LOG.info("Created view!");
      this.participantsViews.set("local", view);
      this.emit(ACSCallEvents.LocalParticpantViewAvailable);
    } catch (error) {
      console.error(error);
    }
  }

  private async removeLocalVideoStream() {
    try {
      this.localVideoStreamRenderer.dispose();
      //localVideoContainer.hidden = true;
    } catch (error) {
      console.error(error);
    }
  }

  private subscribeToRemoteParticipant(remoteParticipant: any) {
    const uuid = remoteParticipant.identifier.communicationUserId;

    this.participants.set(uuid, {
      acsParticipant: remoteParticipant,
    });

    this.emit(ACSCallEvents.RemoteParticipantAdded, uuid);
    try {
      // Inspect the initial remoteParticipant.state value.
      // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
      remoteParticipant.on("stateChanged", () => {});

      // Inspect the remoteParticipants's current videoStreams and subscribe to them.
      remoteParticipant.videoStreams.forEach((remoteVideoStream: any) => {
        this.subscribeToRemoteVideoStream(remoteVideoStream, uuid);
      });
      // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
      // notified when the remoteParticiapant adds new videoStreams and removes video streams.
      remoteParticipant.on("videoStreamsUpdated", (e: any) => {
        // Subscribe to new remote participant's video streams that were added.
        e.added.forEach((remoteVideoStream: any) => {
          this.subscribeToRemoteVideoStream(remoteVideoStream, uuid);
        });
        // Unsubscribe from remote participant's video streams that were removed.
        e.removed.forEach((remoteVideoStream: any) => {
          this.emit(ACSCallEvents.RemoteParticipantViewRemoved, uuid);
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async subscribeToRemoteVideoStream(
    remoteVideoStream: any,
    uuid: any
  ) {
    let renderer = new VideoStreamRenderer(remoteVideoStream);
    let view: any;

    const createView = async () => {
      // Create a renderer view for the remote video stream.
      view = await renderer.createView({
        scalingMode: "Crop",
      });
      // Attach the renderer view to the UI.

      this.participantsViews.set(uuid, view);
      this.emit(ACSCallEvents.RemoteParticipantViewAdded, uuid);
    };

    // Remote participant has switched video on/off
    remoteVideoStream.on("isAvailableChanged", async () => {
      try {
        if (remoteVideoStream.isAvailable) {
          await createView();
        } else {
          view.dispose();
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Remote participant has video on initially.
    if (remoteVideoStream.isAvailable) {
      try {
        await createView();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async setMicrophoneState(state: boolean) {
    try {
      LOG.info("Set microphone state: " + state);
      if (state) {
        await this.call?.unmute();
      } else {
        await this.call?.mute();
      }
    } catch (error: any) {
      LOG.info("Error: " + error);
    }
  }

  async setCameraState(state: boolean) {
    try {
      if (state) {
        this.localVideoStream = await this.createLocalVideoStream();
        this.call?.startVideo(this.localVideoStream);
      } else {
        await this.call?.stopVideo(this.localVideoStream);
      }
    } catch (error: any) {
      LOG.info("Error: " + error);
    }
  }

  getView(uuid: string) {
    return this.participantsViews.get(uuid);
  }
}

export { ACSCallManager };
