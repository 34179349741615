import React from 'react';
import { Box } from '@mui/material';

interface TabPanelProps<T extends string | number> {
  children?: React.ReactNode;
  identifier: T;
  value: T;
}

const TabPanel = <T extends string | number>({
  children,
  value,
  identifier,
  ...other
}: TabPanelProps<T>) => (
  <div
    role="tabpanel"
    hidden={value !== identifier}
    id={`navigation-tabpanel-${identifier}`}
    aria-labelledby={`simple-tab-${identifier}`}
    {...other}
  >
    {value === identifier && <Box>{children}</Box>}
  </div>
);

export default TabPanel;
