import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import { CreateFeatureInput, Feature } from "types/featureFlags";

export async function createFeatureApi(input: CreateFeatureInput) {
  if (!input.name || input.name.length === 0) {
    throw new Error("Feature name is empty");
  }

  if (!input.environmentId || input.environmentId.length === 0) {
    throw new Error("Feature env id is empty");
  }
  
  const result = await RemoteManagementApiClient.getInstance().createFeature(
    input
  );
  if (result && result.createFeature) {
    return result.createFeature as Feature;
  }
}
