import { CopyAllRounded } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";

interface CopyTextfieldProps {
  label: string;
  value: string;
}

export const CopyTextfield = ({ label, value }: CopyTextfieldProps) => (
  <Box sx={{ display: "flex" }}>
    <TextField
      sx={{ width: "100%" }}
      label={label}
      variant="standard"
      value={value}
      disabled
    />

    <Button
      onClick={() => {
        navigator.clipboard.writeText(value);
      }}
      sx={{
        padding: 0,
        margin: 0,
        minWidth: 40,
        height: 30,
        alignSelf: "center",
      }}
      size="small"
    >
      <CopyAllRounded sx={{ width: "30px", height: "30px" }} />
    </Button>
  </Box>
);
