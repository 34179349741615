import { Base64 } from "js-base64";

/**
 * For authentication, AWS Appsync requires the authentication header and payload to be passed
 * as base64 encoded query string parameters
 *
 * @param {String} realTimeEndpoint The websocket endpoint
 * @param {Object} authHeader Header object containing properties for authentication
 * @param {Object} payload The payload to be included.
 * @returns
 */
export function generateWsUrl(
  realTimeEndpoint: any,
  authHeader: any,
  payload: any
) {
  const headerBase64 = Base64.encode(JSON.stringify(authHeader));
  const payloadBase64 = Base64.encode(JSON.stringify(payload));
  return `${realTimeEndpoint}?header=${headerBase64}&payload=${payloadBase64}`;
}
