import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ApiDetails } from "types/apiDetails";
import { Environment } from "types/environment";

interface EnvironmentSettingsFormProps {
  selectedEnvironment: Environment;
  apiDetails: ApiDetails;
  onEnvironmentSelected: (env: string) => void;
  onGraphQLEndpointChanged: (endpoint: string) => void;
  onRealtimeGraphQLEndpointChanged: (endpoint: string) => void;
  onApiKeyChanged: (apiKey: string) => void;
}

const EnvironmentSettingsForm = ({
  selectedEnvironment: selectedApi,
  apiDetails,
  onEnvironmentSelected,
  onGraphQLEndpointChanged,
  onRealtimeGraphQLEndpointChanged,
  onApiKeyChanged,
}: EnvironmentSettingsFormProps) => (
  <Box
    component="form"
    noValidate
    sx={{
      marginTop: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
    }}
  >
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" variant="standard">
        Use environment settings
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Environment"
        variant="standard"
        value={selectedApi}
        onChange={(event: SelectChangeEvent<string>) => {
          onEnvironmentSelected(event.target.value);
        }}
      >
        <MenuItem value="development">Development</MenuItem>
        <MenuItem value="staging">Staging</MenuItem>
        <MenuItem value="production">Production</MenuItem>
        <MenuItem value="custom">Custom</MenuItem>
      </Select>
    </FormControl>
    <TextField
      id="calling-graphql-endpoint"
      label="GraphQL endpoint"
      value={apiDetails.graphQLEndpoint}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onGraphQLEndpointChanged(event.target.value);
      }}
      variant="standard"
    />
    <TextField
      id="calling-realtime-graphql-endpoint"
      label="Realtime GraphQL endpoint"
      variant="standard"
      value={apiDetails.realtimeGraphQLEndpoint}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onRealtimeGraphQLEndpointChanged(event.target.value);
      }}
    />
    <TextField
      id="calling-api-key"
      label="API key"
      variant="standard"
      value={apiDetails.apiKey}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onApiKeyChanged(event.target.value);
      }}
    />
  </Box>
);

export default EnvironmentSettingsForm;
