import { ApiClient } from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import { ParticipationManager } from "calling/ParticipationManager";

const MAX_OFFSET = 60 * 1000 * 60 * 3;
export interface UpdateRoomProps {
  entityId?: string;
  appContentData?: string;
  providerId?: string;
  isPlaying?: boolean;
  playbackOffset?: number;
}
export async function updateRoomApi(updateRoomProps: UpdateRoomProps) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }
  const apiClient = ApiClient.getInstance(apiDetails!);
  const participationManager = ParticipationManager.getInstance();
  const room = participationManager.getRoom();
  if (!room) {
    throw new Error("Room is null");
  }

  if (
    room.playbackOffset &&
    updateRoomProps.playbackOffset &&
    Math.abs(updateRoomProps.playbackOffset - room.playbackOffset) > MAX_OFFSET
  ) {
    throw Error("Max offset is 3h.");
  }

  const input = {
    entityId:
      updateRoomProps.entityId !== undefined
        ? updateRoomProps.entityId
        : room.entityId,
    appContentData:
      updateRoomProps.appContentData !== undefined
        ? updateRoomProps.appContentData
        : room.appContentData,
    providerId:
      updateRoomProps.providerId !== undefined
        ? updateRoomProps.providerId
        : room.providerId,
    isPlaying:
      updateRoomProps.isPlaying !== undefined
        ? updateRoomProps.isPlaying
        : room.isPlaying,
    playbackOffset:
      updateRoomProps.playbackOffset !== undefined
        ? updateRoomProps.playbackOffset
        : room.playbackOffset,
  };

  const result = await apiClient.updateRoom(room.roomCode, input);
  participationManager.setRoom(result);
  if (result.room) {
    return true;
  } else {
    return false;
  }
}
