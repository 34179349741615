import { memo } from "react";
import { InputAdornment, Tooltip } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";

interface InfoTextType {
  infoText?: string;
}

const InfoText = memo(({ infoText }: InfoTextType) => (
  <InputAdornment position="end">
    <Tooltip title={infoText}>
      <InfoIcon color="info" />
    </Tooltip>
  </InputAdornment>
));

export default InfoText;
