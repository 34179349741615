import { ACSCallManager } from "calling/ACSCallManager";
import { Microphone } from "types/peripherals";

export interface SetMicrophoneProps {
  microphone: Microphone;
}

export async function setMicrophoneApi(setMicrophoneProps: SetMicrophoneProps) {
  const callClient = ACSCallManager.getInstance();
  return await callClient.setMicrophone(setMicrophoneProps.microphone);
}
