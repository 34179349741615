import { ACSCallManager } from "calling/ACSCallManager";
import { Camera } from "types/peripherals";

export interface SetCameraProps {
  camera: Camera;
}

export async function setCameraApi(setCameraProps: SetCameraProps) {
  const callClient = ACSCallManager.getInstance();
  return await callClient.setCamera(setCameraProps.camera);
}
