import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

interface RoomEventsTableProps {
  roomEvents: any[];
}

const RoomEventsTable = ({ roomEvents }: RoomEventsTableProps) => (
  <DataGrid
    getRowId={(row) => row.time}
    rows={roomEvents}
    columns={[
      {
        field: "time",
        headerName: "Event Time",
        width: 150,
        valueFormatter: (params: any) =>
          dayjs(params.value).format("HH:mm:ss:SSS"),
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: 150,
      },
      {
        field: "playbackOffset",
        headerName: "Playback Offset",
        width: 150,
        valueFormatter: (params: any) =>
          dayjs(params.value).format("HH:mm:ss:SSS"),
      },
      {
        field: "entityId",
        headerName: "Entity ID",
        width: 150,
      },
      {
        field: "appContentData",
        headerName: "App Content Data",
        width: 150,
      },
      {
        field: "isPlaying",
        headerName: "Is playing",
        width: 150,
      },
      {
        field: "providerId",
        headerName: "Provider ID",
        width: 150,
      },
      {
        field: "vcId",
        headerName: "VC ID",
        width: 150,
      },
      {
        field: "metadata",
        headerName: "Metadata",
        width: 150,
      },
    ]}
    pageSizeOptions={[5, 10, 25]}
    initialState={{
      pagination: { paginationModel: { pageSize: 5 } },
    }}
  ></DataGrid>
);

export default RoomEventsTable;
