import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export interface PeripheralItem {
  id: string;
  name: string;
}

export interface PeripheralSelectProps {
  label: String,
  peripherals: Array<PeripheralItem>;
  selectedPeripheral?: PeripheralItem;
  onPeripheralSelected: (peripheral: PeripheralItem) => void;
}

export const PeripheralSelect = ({
  label,
  peripherals,
  selectedPeripheral,
  onPeripheralSelected,
}: PeripheralSelectProps) => (
  <FormControl fullWidth>
    <InputLabel variant="standard">{label}</InputLabel>
    <Select
      label="Environment"
      variant="standard"
      value={selectedPeripheral?.id ?? peripherals[0].id}
      onChange={(event: SelectChangeEvent<string>) => {
        const item = peripherals.find((item) => item.id === event.target.value)
        if (item) {
          onPeripheralSelected(item)
        }
      }}
    >
      {peripherals.map((peripheral) => (
        <MenuItem key={peripheral.id} value={peripheral.id}>
          {peripheral.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
