import { ParticipationDetails } from "types/participationDetails";
import { Room } from "types/room";

export class ParticipationManager {
  private static instance: ParticipationManager;
  private participationDetails?: ParticipationDetails | null;
  private room?: Room | null;

  public static getInstance(): ParticipationManager {
    if (!ParticipationManager.instance) {
      ParticipationManager.instance = new ParticipationManager();
    }

    return ParticipationManager.instance;
  }

  setParticipationDetails(participationDetails?: ParticipationDetails | null) {
    this.participationDetails = participationDetails;
  }

  getParticipationDetails() {
    return this.participationDetails;
  }

  setRoom(room?: Room | null) {
    this.room = room;
  }

  getRoom() {
    return this.room;
  }
}
