import { createContext, useCallback, useEffect, useState } from 'react';

export type TabsContextProps = {
  tabs: string[];
  selectedTab?: string;
  changeTab(newTab: string): void;
};

export const TabsContext = createContext<TabsContextProps>({ tabs: [], changeTab: () => {} });

export const TabsProvider = ({
  children,
  tabs,
  initialTab,
  onChangeTab,
}: {
  children: any;
  tabs: string[];
  initialTab: string | undefined;
  onChangeTab(newTab: string): void;
}) => {
  const [tab, setTab] = useState(initialTab);

  const handleChangeTab = useCallback(
    (newTab: string) => {
      setTab(newTab);
      onChangeTab(newTab);
    },
    [onChangeTab]
  );

  useEffect(() => {
    setTab(initialTab);
  }, [initialTab]);

  return (
    <TabsContext.Provider
      value={{
        tabs,
        selectedTab: tab || tabs?.[0],
        changeTab: (newTab) => handleChangeTab(newTab),
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};
