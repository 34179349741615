import { useAppDispatch, useAppSelector } from "app/hooks";
import { memo, useEffect } from "react";
import { usePermissions } from "permissions/PermissionProvider";
import {  Button, Stack, Typography } from "@mui/material";
import LoadingBackdrop from "components/LoadingBackdrop";
import SectionCard from "components/SectionCard";
import {
  clearMessage,
  createFeatureEnvironment,
  deleteFeatureEnvironment,
  initialise,
  selectFeatureEnvironmentsState,
  setDialogOpen,
  setEditEnvironment,
  updateFeatureEnvironment,
} from "./featureEnvironmentsSlice";
import FeatureEnvironmentsDataGrid from "components/featureEnvironments/FeatureEnvironmentsDataGrid";
import FeatureEnvironmentDialog from "../../components/featureEnvironments/FeatureEnvironmentDialog";
import AddIcon from "@mui/icons-material/Add";
import { toastError, toastInfo } from "util/toast";

const FeatureEnvironmentsPage = memo(() => {
  const dispatch = useAppDispatch();
  const featureEnvironmentsState = useAppSelector(
    selectFeatureEnvironmentsState
  );
  const permissions = usePermissions();

  useEffect(() => {
    if (!featureEnvironmentsState.initialised) {
      dispatch(initialise(permissions.groups));
    }
  }, [dispatch, featureEnvironmentsState.initialised, permissions]);

  useEffect(() => {
    if (featureEnvironmentsState.message) {
      if (featureEnvironmentsState.message.isSuccess) {
        toastInfo(featureEnvironmentsState.message.message);
      } else {
        toastError(featureEnvironmentsState.message.message);
      }
    
        dispatch(clearMessage());
    }
  }, [dispatch, featureEnvironmentsState.message]);

  return (
    <Stack>
      <SectionCard>
        <Typography
          align="center"
          variant="h5"
          sx={{ marginBottom: 2 }}
          id={"feature-environments-page-title"}
        >
          Feature Environments
        </Typography>

        <Button
          color="primary"
          startIcon={<AddIcon />}
          id={"feature-environments-page-create-button"}
          onClick={() => {
            dispatch(setDialogOpen(true));
          }}
        >
          Add environment
        </Button>
        <FeatureEnvironmentsDataGrid
          environments={featureEnvironmentsState.environments}
          handleEdit={(environment) => {
            dispatch(setEditEnvironment(environment));
            dispatch(setDialogOpen(true));
          }}
          handleDelete={(environment) =>
            dispatch(deleteFeatureEnvironment(environment))
          }
        />
      </SectionCard>
      <FeatureEnvironmentDialog
        open={featureEnvironmentsState.dialogOpen}
        environment={featureEnvironmentsState.editEnvironment}
        environments={featureEnvironmentsState.environments}
        handleCancel={() => {
          dispatch(setDialogOpen(false));
          dispatch(setEditEnvironment(undefined));
        }}
        handleEdit={(env) => {
          dispatch(setEditEnvironment(undefined));
          dispatch(updateFeatureEnvironment(env));
          dispatch(setDialogOpen(false));
        }}
        handleSave={(env) => {
          dispatch(createFeatureEnvironment(env));
          dispatch(setDialogOpen(false));
        }}
      />
      <LoadingBackdrop
        open={featureEnvironmentsState.isLoading}
      ></LoadingBackdrop>
    </Stack>
  );
});

export default FeatureEnvironmentsPage;
