import { memo } from "react";
import { FormFieldType } from "types/forms";
import AutoComplete from "./AutoComplete";
import { FeatureEnvironment } from "types/featureEnvironments";

interface FeatureEnvironmentsSelectType extends FormFieldType {
  environments: FeatureEnvironment[];
}

const FeatureEnvironmentsSelect = memo(
  ({ environments, ...restProps }: FeatureEnvironmentsSelectType) => {
    return (
      <AutoComplete
        items={environments?.map((environment) => ({
          label: environment.name,
          id: environment.id,
        }))}
        {...restProps}
      />
    );
  }
);

export default FeatureEnvironmentsSelect;
