import { getMainDefinition } from "@apollo/client/utilities";
import { split } from "@apollo/client";

/**
 * Ensure that HTTP is used for queries and mutations, but websockets for subscriptions
 * @param {*} httpLink The Http link
 * @param {*} websocketLink The websocket link
 * @returns A new link
 */
export function createSplitLink(httpLink: any, websocketLink: any) {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    websocketLink,
    httpLink
  );
}
