import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { FeatureEnvironment } from "types/featureEnvironments";
import {
  CreateFeatureInput,
  DeleteFeatureInput,
  Feature,
  UpdateFeatureInput,
} from "types/featureFlags";
import {
  SnackbarMessage,
  buildErrorMessage,
  buildSuccessMessage,
} from "types/snackbarMessage";
import { getEnvironmentsApi } from "usecase/environments/getEnvironments";
import { createFeatureApi } from "usecase/features/createFeature";
import { deleteFeatureApi } from "usecase/features/deleteFeature";
import { getFeaturesApi } from "usecase/features/getFeatures";
import { updateFeatureApi } from "usecase/features/updateFeature";

export interface FeatureFlagsState {
  isLoading: boolean;
  initialised: boolean;
  features: Feature[];
  environments: FeatureEnvironment[];
  selectedEnvironment?: FeatureEnvironment;
  message?: SnackbarMessage;
  dialogOpen: boolean;
  editFeature?: Feature;
}

const initialState: FeatureFlagsState = {
  isLoading: false,
  initialised: false,
  features: [],
  environments: [],
  dialogOpen: false,
};

export const initialise = createAsyncThunk(
  "featureFlags/initialise",
  async (permissions: String[]) => {
    const environments = await getEnvironmentsApi(permissions);
    return environments;
  }
);

export const listFeatureFlags = createAsyncThunk(
  "featureFlags/listFeatureFlags",
  async (environment: FeatureEnvironment) => {
    const features = await getFeaturesApi({ environmentId: environment.id });
    return features;
  }
);

export const createFeature = createAsyncThunk(
  "featureFlags/createFeature",
  async (input: CreateFeatureInput) => {
    return createFeatureApi(input);
  }
);

export const updateFeature = createAsyncThunk(
  "featureFlags/updateFeature",
  async (input: UpdateFeatureInput) => {
    return updateFeatureApi(input);
  }
);

export const deleteFeature = createAsyncThunk(
  "featureFlags/deleteFeature",
  async (input: DeleteFeatureInput) => {
    return deleteFeatureApi(input);
  }
);

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    clearMessage(state) {
      state.message = undefined;
    },
    setDialogOpen(state, action) {
      state.dialogOpen = action.payload;
    },
    setEditFeature(state, action) {
      state.editFeature = action.payload;
    },
    setSelectedEnvironment(state, action) {
      state.selectedEnvironment = action.payload;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(initialise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialise.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          state.environments = result;
          state.isLoading = false;
        }
        state.initialised = true;
      })
      .addCase(initialise.rejected, (state, action) => {
        state.message = buildErrorMessage(
          `Failed to select environments: ${action.error.message}`
        );
        state.initialised = true;
        state.isLoading = false;
      })
      .addCase(listFeatureFlags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listFeatureFlags.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          state.features = result;
        }
        state.isLoading = false;
      })
      .addCase(listFeatureFlags.rejected, (state, action) => {
        state.message = buildErrorMessage(
          `Failed to select features for environment: ${action.error.message}`
        );
        state.isLoading = false;
      })

      .addCase(createFeature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFeature.fulfilled, (state, action) => {
        if (action.payload) {
          state.features = [...state.features, action.payload];
        }
        state.isLoading = false;
        state.message = buildSuccessMessage("Feature created.");
      })
      .addCase(createFeature.rejected, (state, action) => {
        state.isLoading = false;
        state.message = buildErrorMessage(
          `Failed to create feature: ${action.error.message}`
        );
        state.features = [...state.features];
      })

      .addCase(updateFeature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFeature.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedFeature = action.payload;
          const index = state.features.findIndex(
            (feature) => feature.id === updatedFeature.id
          );
          const features = state.features;
          features[index] = updatedFeature;
          state.features = features;
        }
        state.isLoading = false;
        state.message = buildSuccessMessage("Feature updated.");
      })
      .addCase(updateFeature.rejected, (state, action) => {
        state.isLoading = false;
        state.message = buildErrorMessage(
          `Failed to update feature: ${action.error.message}`
        );
      })

      .addCase(deleteFeature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFeature.fulfilled, (state, action) => {
        if (action.payload) {
          const deletedFeature = action.payload;
          state.features = state.features.filter(
            (feature) => feature.id !== deletedFeature.id
          );
        }
        state.isLoading = false;
        state.message = buildSuccessMessage("Feature deleted.");
      })
      .addCase(deleteFeature.rejected, (state, action) => {
        state.isLoading = false;
        state.message = buildErrorMessage(
          `Failed to delete feature: ${action.error.message}`
        );
      });
  },
});
export const {
  clearMessage,
  setEditFeature,
  setDialogOpen,
  setSelectedEnvironment,
} = featureFlagsSlice.actions;
export const selectFeatureFlagsState = (state: RootState) => state.featureFlags;

export default featureFlagsSlice.reducer;
