import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import callingSlice from "pages/calling/slices/callingSlice";
import featureEnvironmentsSlice from "pages/featureEnvironments/featureEnvironmentsSlice";
import featureFlagsSlice from "pages/featureflags/featureFlagsSlice";

export const store = configureStore({
  reducer: {
    calling: callingSlice,
    featureFlags: featureFlagsSlice,
    featureEnvironments: featureEnvironmentsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
