import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

interface ParticipantEventsTableProps {
  participantEvents: any[];
}

const ParticipantEventsTable = ({
  participantEvents,
}: ParticipantEventsTableProps) => (
  <DataGrid
    getRowId={(row) => row.time}
    rows={participantEvents}
    columns={[
      {
        field: "time",
        headerName: "Event Time",
        width: 150,
        valueFormatter: (params: any) =>
          dayjs(params.value).format("HH:mm:ss:SSS"),
      },
      {
        field: "id",
        headerName: "Id",
        width: 150,
      },
      {
        field: "azureUserId",
        headerName: "Azure User ID",
        width: 150,
      },
      {
        field: "metadata",
        headerName: "Metadata",
        width: 150,
      },
      {
        field: "nickname",
        headerName: "Nickname",
        width: 150,
      },
      {
        field: "cameraHardwareEnabled",
        headerName: "Camera Hardware Enabled",
        width: 150,
      },
      {
        field: "micEnabled",
        headerName: "Mic Enabled",
        width: 150,
      },
      {
        field: "cameraEnabled",
        headerName: "Camera Enabled",
        width: 150,
      },
    ]}
    pageSizeOptions={[5, 10, 25]}
    initialState={{
      pagination: { paginationModel: { pageSize: 5 } },
    }}
  />
);

export default ParticipantEventsTable;
