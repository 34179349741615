import { memo, ReactNode } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { getDataAttributes } from '../util/dataAttributes';
import { Variant } from '@mui/material/styles/createTypography';

interface SectionCardProps {
  title?: string;
  variant?: Variant;
  component?: React.ElementType;
  children: ReactNode;
}

const SectionCard = memo(
  ({ title, variant = 'h5', component = Card, children, ...restProps }: SectionCardProps) => {
    return (
      <Box
        component={component}
        sx={{
          m: 1,
        }}
        {...getDataAttributes(restProps)}
      >
        {title && <CardHeader title={<Typography variant={variant}>{title}</Typography>} />}
        <CardContent>{children}</CardContent>
      </Box>
    );
  }
);

export default SectionCard;
