import { ParticipationManager } from "calling/ParticipationManager";
import { ACSCallManager } from "calling/ACSCallManager";

export async function initCallApi() {
  const participationManager = ParticipationManager.getInstance();
  const participationDetails = participationManager.getParticipationDetails();
  if (!participationDetails) {
    throw new Error("Participation details is empty");
  }
  const room = participationManager.getRoom();
  if (!room) {
    throw new Error("Room is null");
  }

  await ACSCallManager.getInstance().init(room, participationDetails);
}
