import { compare } from 'semver';

export const basicCompare = (a: any, b: any): 1 | 0 | -1 => (a > b ? 1 : -1);
export const semverCompare = (a: any, b: any): 1 | 0 | -1 => compare(a, b);
export const caseInsensitiveCompare = (a: any, b: any): 1 | 0 | -1 => {
  if (typeof a === 'string' || a instanceof String) {
    const comparison = a.localeCompare(b, undefined, { sensitivity: 'base' });

    //on some browsers localeCompare will return a value other than -1/0/1
    //so standardise with our other comparison functions
    //fall back to basic compare to guarantee order for different casing of the same word
    return comparison < 0 ? -1 : comparison > 0 ? 1 : basicCompare(a, b);
  } else {
    return basicCompare(a, b);
  }
};
export const sortByField = <T>(
  array: T[] | undefined,
  field: keyof T,
  ascending = true,
  compare = caseInsensitiveCompare
): T[] | undefined => {
  if (!array) {
    return;
  }
  const sorted = [...array];
  sorted.sort((a, b) => {
    return compare(a[field as keyof T], b[field as keyof T]) * (ascending ? 1 : -1);
  });
  return sorted;
};

export const sortByFields = <T>(
  array: T[] | undefined,
  fields: (keyof T)[],
  ascending = true,
  compare = caseInsensitiveCompare
): T[] | undefined => {
  if (!array) {
    return;
  }

  const getComparitor = (item: T) => {
    for (const field of fields) {
      if (item[field]) {
        return item[field];
      }
    }
  };

  const sorted = [...array];
  sorted.sort((a, b) => {
    return compare(getComparitor(a), getComparitor(b)) * (ascending ? 1 : -1);
  });
  return sorted;
};

export const sortBySemverField = (array: any[] | undefined, field: string, ascending = true) =>
  sortByField(array, field, ascending, semverCompare);
