import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FeatureEnvironment } from "types/featureEnvironments";

interface FeatureEnvironmentsSelectProps {
  environments: FeatureEnvironment[];
  selectedEnvironment?: FeatureEnvironment;
  selectEnvironment: (environment: FeatureEnvironment) => void;
  sx?: any;
}

export default function FeatureEnvironmentSelect({
  environments,
  selectedEnvironment,
  selectEnvironment,
  sx,
}: FeatureEnvironmentsSelectProps) {
  return (
    <FormControl fullWidth sx={sx} id="feature-env-select">
      <InputLabel id="feature-env-label">Environment</InputLabel>
      <Select
        labelId="feaute-env-selector"
        value={selectedEnvironment?.id ?? ""}
        label="Environment"
        onChange={(event) => {
          const envs = environments.filter(
            (env) => env.id === event.target.value
          );
          selectEnvironment(envs[0]);
        }}
      >
        {environments.map((env, index) => (
          <MenuItem
            id={`feature-env-select-item-${index}`}
            key={env.id}
            value={env.id}
          >
            {env.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
