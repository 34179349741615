import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import { Feature, UpdateFeatureInput } from "types/featureFlags";

export async function updateFeatureApi(input: UpdateFeatureInput) {
  const result = await RemoteManagementApiClient.getInstance().updateFeature(
    input
  );
  if (result && result.updateFeature) {
    return result.updateFeature as Feature;
  }
}
