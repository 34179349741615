import { Auth } from "aws-amplify";
import LOG from "logging/Logger";

export async function getCognitoIdToken() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user && user.signInUserSession) {
      return user.signInUserSession.idToken.jwtToken;
    }
  } catch (error) {
    LOG.error(error);
  }
  return null;
}
