import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import { DeleteFeatureInput, Feature } from "types/featureFlags";

export async function deleteFeatureApi(input: DeleteFeatureInput) {
  const result = await RemoteManagementApiClient.getInstance().deleteFeature(
    input
  );
  if (result && result.deleteFeature) {
    return result.deleteFeature as Feature;
  }
}
