import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import LOG from "logging/Logger";
import { FeatureEnvironment } from "types/featureEnvironments";
import { DeleteFeatureInput } from "types/featureFlags";
import { deleteFeatureApi } from "usecase/features/deleteFeature";
import { getFeaturesApi } from "usecase/features/getFeatures";

/**
 * Currently due to problems with API, frontend app handles deleting feature flags
 * from provided source environment.
 * In the future it should be refactored and feature flags should be deleted on
 * backend side.
 * TODO: Remove feature flags delete from here.
 */
export async function deleteEnvironmentApi(environment: FeatureEnvironment) {
  const features = await getFeatures(environment);
  if (features) {
    for (let index = 0; index < features.length; index++) {
      const feature = features[index];
      const deletedFeature = await deleteFeature({ id: feature.id });
      if (!deletedFeature) {
        LOG.error(`Failed to delete feature: ${feature}`);
      } else {
        LOG.debug(`Deleting feature: ${index + 1}/${features.length}`);
      }
    }
  }

  const result =
    await RemoteManagementApiClient.getInstance().deleteEnvironment({
      id: environment.id,
    });
  if (result && result.deleteEnvironment) {
    return result.deleteEnvironment as FeatureEnvironment;
  }
}

async function getFeatures(sourceEnvironment: FeatureEnvironment) {
  try {
    return getFeaturesApi({ environmentId: sourceEnvironment.id });
  } catch (error) {
    LOG.error(error);
  }
}

async function deleteFeature(feature: DeleteFeatureInput) {
  try {
    return deleteFeatureApi(feature);
  } catch (error) {
    LOG.error(error);
  }
}