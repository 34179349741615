import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import LoadingBackdrop from "components/LoadingBackdrop";
import SectionCard from "components/SectionCard";
import FeatureFlagsDataGrid from "components/featureflags/FeatureFlagsDataGrid";
import { memo, useEffect } from "react";
import {
  clearMessage,
  createFeature,
  deleteFeature,
  initialise,
  listFeatureFlags,
  selectFeatureFlagsState,
  setDialogOpen,
  setEditFeature,
  setSelectedEnvironment,
  updateFeature,
} from "./featureFlagsSlice";
import FeatureEnvironmentSelect from "components/featureflags/FeatureEnvironmentSelect";
import { usePermissions } from "permissions/PermissionProvider";
import FeatureFlagDialog from "../../components/featureflags/FeatureFlagDialog";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import { toastError, toastInfo } from "util/toast";
import {} from "components/toasts/ToastContent";

const FeatureFlagsPage = memo(() => {
  const dispatch = useAppDispatch();
  const featureFlagsState = useAppSelector(selectFeatureFlagsState);
  const permissions = usePermissions();
  const envsAvailable = featureFlagsState.environments.length > 0;
  const location = useLocation();

  useEffect(() => {
    if (!featureFlagsState.initialised) {
      dispatch(initialise(permissions.groups));
    }
  }, [dispatch, featureFlagsState.initialised, permissions]);

  useEffect(() => {
    if (featureFlagsState.message) {
      if (featureFlagsState.message.isSuccess) {
        toastInfo(featureFlagsState.message.message);
      } else {
        toastError(featureFlagsState.message.message);
      }
      dispatch(clearMessage());
    }
  }, [dispatch, featureFlagsState.message]);

  useEffect(() => {
    if (
      !featureFlagsState.selectedEnvironment &&
      featureFlagsState.environments.length > 0
    ) {
      const env = featureFlagsState.environments[0];
      dispatch(setSelectedEnvironment(env));
      dispatch(listFeatureFlags(env));
    }
  }, [
    featureFlagsState.selectedEnvironment,
    featureFlagsState.environments,
    dispatch,
  ]);

  useEffect(() => {
    if (featureFlagsState.initialised) {
      dispatch(initialise(permissions.groups));
    }
  }, [dispatch, featureFlagsState.initialised, location, permissions.groups]);

  return (
    <Stack>
      <LoadingBackdrop open={featureFlagsState.isLoading}></LoadingBackdrop>
      <SectionCard>
        <Typography
          id="feature-flags-page-title"
          align="center"
          variant="h5"
          sx={{ marginBottom: 2 }}
        >
          Feature Flags
        </Typography>

        {envsAvailable ? (
          <FeatureEnvironmentSelect
            selectedEnvironment={featureFlagsState.selectedEnvironment}
            environments={featureFlagsState.environments}
            selectEnvironment={(environment) => {
              dispatch(setSelectedEnvironment(environment));
              dispatch(listFeatureFlags(environment));
            }}
            sx={{ marginBottom: envsAvailable ? 2 : 0 }}
          />
        ) : featureFlagsState.initialised ? (
          <Typography
            id="feature-flags-page-no-envs-error"
            align="center"
            sx={{ marginBottom: 2 }}
          >
            There's no environments available for you.
          </Typography>
        ) : (
          ""
        )}
        {featureFlagsState.selectedEnvironment != null ? (
          <div>
            <Button
              id="feature-flags-page-create-button"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                dispatch(setDialogOpen(true));
              }}
            >
              Add feature flag
            </Button>
            <FeatureFlagsDataGrid
              features={featureFlagsState.features}
              handleEdit={(feature) => {
                dispatch(setEditFeature(feature));
                dispatch(setDialogOpen(true));
              }}
              handleDelete={(input) => dispatch(deleteFeature(input))}
            />
          </div>
        ) : (
          ""
        )}
      </SectionCard>
      <FeatureFlagDialog
        feature={featureFlagsState.editFeature}
        open={featureFlagsState.dialogOpen}
        environment={featureFlagsState.selectedEnvironment!}
        handleCancel={() => {
          dispatch(setEditFeature(undefined));
          dispatch(setDialogOpen(false));
        }}
        handleEdit={(env) => {
          dispatch(setEditFeature(undefined));
          dispatch(updateFeature(env));
          dispatch(setDialogOpen(false));
        }}
        handleSave={(env) => {
          dispatch(createFeature(env));
          dispatch(setDialogOpen(false));
        }}
      />
      <LoadingBackdrop open={featureFlagsState.isLoading}></LoadingBackdrop>
    </Stack>
  );
});

export default FeatureFlagsPage;
