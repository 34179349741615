import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import {
  FeatureEnvironment,
  UpdateFeatureEnvironmentInput,
} from "types/featureEnvironments";

export async function updateEnvironmentApi(
  input: UpdateFeatureEnvironmentInput
) {
  if (!input.name || input.name.length === 0) {
    throw new Error("Feature name is empty");
  }

  if (!input.cognitoGroupName || input.cognitoGroupName.length === 0) {
    throw new Error("Cognito group name is empty");
  }

  const result =
    await RemoteManagementApiClient.getInstance().updateEnvironment(input);
  if (result && result.updateEnvironment) {
    return result.updateEnvironment as FeatureEnvironment;
  }
}
