import FormDialog from "components/form/FormDialog";
import FeatureEnvironmentsSelect from "components/form/fields/FeatureEnvironmentsSelect";
import TextField from "components/form/fields/TextField";
import { Fragment, memo } from "react";
import { FieldValues } from "react-hook-form";
import {
  CreateFeatureEnvironmentProps,
  FeatureEnvironment,
  UpdateFeatureEnvironmentInput,
} from "types/featureEnvironments";

export interface FeatureEnvironmentDialogProps {
  environment?: FeatureEnvironment;
  environments: FeatureEnvironment[];
  open: boolean;
  handleCancel: () => void;
  handleEdit: (env: UpdateFeatureEnvironmentInput) => void;
  handleSave: (env: CreateFeatureEnvironmentProps) => void;
}

const FeatureEnvironmentDialog = memo(
  ({
    environment,
    environments,
    open,
    handleCancel,
    handleEdit,
    handleSave,
  }: FeatureEnvironmentDialogProps) => {
    const isCreate = environment === undefined;
    const title = isCreate ? "Create environment" : "Edit environment";

    const onSave = (data: FieldValues) => {
      if (isCreate) {
        handleSave({
          sourceEnvironment: environments.filter(
            (env) => env.id === data.source
          )[0],
          input: {
            name: data.name,
            description: data.description ?? "",
            cognitoGroupName: data.cognitoGroupName,
          },
        });
      } else {
        handleEdit({
          id: environment.id,
          name: data.name,
          description: data.description ?? "",
          cognitoGroupName: data.cognitoGroupName,
        });
      }
    };

    return (
      <Fragment>
        <FormDialog
          name="featureEnvironmentDialog"
          title={title}
          open={open}
          onSave={onSave}
          onCancel={handleCancel}
          allowSaveUnchanged={true}
          defaultValues={environment}
        >
          {isCreate ? (
            <FeatureEnvironmentsSelect
              environments={environments}
              label="Create based on"
              name="source"
              required={false}
            />
          ) : (
            ""
          )}
          <TextField name="name" label="Name" required={true} />
          <TextField name="description" label="Description" required={false} />
          <TextField
            name="cognitoGroupName"
            label="Cognito Group Name"
            required={true}
            helpText="Use `FeatureFlags` as default value here."
          />
        </FormDialog>
      </Fragment>
    );
  }
);
export default FeatureEnvironmentDialog;
