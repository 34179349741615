/**
 * Create the header properties required for Authorization authentication
 * @param {String} graphQLEndpoint The graphQL endpoint
 * @param {String} idToken The id token
 * @returns
 */
export function createAuthorizationHeader(graphQLEndpoint: any, idToken: any) {
  const urlParser = new URL(graphQLEndpoint);
  return {
    host: urlParser.host,
    Authorization: idToken,
  };
}
