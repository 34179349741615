// These do something vaguely sensible if an undefined value provided,
// but they don't check things like tab values are in is an allowed list,
// didn't seem worth the effort


export const appPath = (appId?: string | undefined) => (appId ? `/apps/${appId}` : '/apps');
export const appVersionPath = (appId: string | undefined, appVersion: string | undefined) => {
  const baseUrl = appPath(appId);
  if (appId && appVersion) {
    return `${baseUrl}/${appVersion}`;
  }
  return baseUrl;
};


export const firmwarePath = (firmwareId?: string | undefined) =>
  firmwareId ? `/firmware/${firmwareId}` : '/firmware';
export const firmwareVersionPath = (firmwareId: string | undefined, firmwareVersion: string | undefined) => {
  const baseUrl = firmwarePath(firmwareId);
  if (firmwareId && firmwareVersion) {
    return `${baseUrl}/${firmwareVersion}`;
  }
  return baseUrl;
};

export const groupsPath = () => '/groups';

export const groupAppsPath = (groupId: string | undefined) =>
  groupId ? `/groups/${groupId}/apps` : '/groups';

export const groupTabPath = (groupId: string | undefined, tab: string | undefined) =>
  groupId && tab ? `/groups/${groupId}/${tab}` : groupAppsPath(groupId);

export const teamPath = (teamId: string | undefined) => (teamId ? `/teams/${teamId}` : '/teams');
export const teamsPath = () => `/teams`;
export const teamTabPath = (teamId: string | undefined, tab: string | undefined) =>
  teamId && tab ? `/teams/${teamId}/${tab}` : teamsPath();

export const permissionsTabPath = (tab: string | undefined) =>
  tab ? `/admin/permissions/${tab}` : '/admin/permissions/users';

export const navigationTabPath = (tab: string | undefined) => `/${tab?.toLowerCase()}`;

export const statusTabPath = (tab: string | undefined) =>
  tab ? `/admin/status/${tab}` : '/admin/status/apps';
