type InputPropType = {
  [key: string]: string;
};

export const getDataAttributes = (inputProps: InputPropType = {}) =>
  Object.keys(inputProps).reduce((acc: InputPropType, key) => {
    if (key.startsWith('data-')) {
      acc[key] = inputProps[key];
    }

    return acc;
  }, {});

export const convertCamelCaseToKebabCase = (name: string) =>
  name.replace(/([A-Z])/g, '-$1').toLowerCase();
