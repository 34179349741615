import { FormControl } from "@mui/material";
import { Room } from "types/room";
import { CopyTextfield } from "./CopyTextfield";

interface RoomDetailsFormProps {
  room: Room;
}

const RoomDetailsForm = ({ room }: RoomDetailsFormProps) => (
  <FormControl fullWidth>
    <CopyTextfield label="Room code" value={room.roomCode ?? ""} />
    <CopyTextfield label="Metadata" value={room.metadata ?? ""} />
    <CopyTextfield label="Entity ID" value={room.entityId ?? ""} />
    <CopyTextfield label="Provider ID" value={room.providerId ?? ""} />
  </FormControl>
);

export default RoomDetailsForm;
