import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import {
  CreateFeatureEnvironmentInput,
  FeatureEnvironment,
} from "types/featureEnvironments";
import { getFeaturesApi } from "usecase/features/getFeatures";
import LOG from "logging/Logger";
import { CreateFeatureInput, Feature } from "types/featureFlags";
import { createFeatureApi } from "usecase/features/createFeature";

/**
 * Currently due to problems with API, frontend app handles copying feature flags
 * from provided source environment.
 * In the future it should be refactored and feature flags should be copied on
 * backend side.
 * TODO: Remove feature flags copy from here.
 */
export async function createEnvironmentApi(
  input: CreateFeatureEnvironmentInput,
  sourceEnvironment?: FeatureEnvironment
) {
  const environment = await createEnvironment(input);
  if (!environment) {
    throw new Error("Environment has been not created.");
  }

  if (sourceEnvironment) {
    const features = await getFeatures(sourceEnvironment);
    if (features) {
      for (let index = 0; index < features.length; index++) {
        const feature = features[index];
        const copiedFeature = await createFeature(
          buildFeatureInput(feature, environment.id)
        );
        if (!copiedFeature) {
          LOG.error(`Failed to create feature: ${feature}`);
        } else {
          LOG.debug(`Copying feature: ${index + 1}/${features.length}`);
        }
      }
    } else {
      LOG.debug("Features list is empty. Not creating new feature flags.");
    }
  }
  return environment;
}

function buildFeatureInput(
  feature: Feature,
  environmentId: string
): CreateFeatureInput {
  return {
    name: feature.name,
    description: feature.description,
    enabled: feature.enabled,
    environmentId: environmentId,
  };
}


async function createEnvironment(input: CreateFeatureEnvironmentInput) {
  try {
    if (!input.name || input.name.length === 0) {
      throw new Error("Name is empty");
    }

    if (!input.cognitoGroupName || input.cognitoGroupName.length === 0) {
      throw new Error("Cognito group name is empty");
    }

    const result =
      await RemoteManagementApiClient.getInstance().createEnvironment(input);
    if (result && result.createEnvironment) {
      return result.createEnvironment as FeatureEnvironment;
    }
  } catch (error) {
    LOG.error(error);
  }
}

async function getFeatures(sourceEnvironment: FeatureEnvironment) {
  try {
    return getFeaturesApi({ environmentId: sourceEnvironment.id });
  } catch (error) {
    LOG.error(error);
  }
}

async function createFeature(feature: CreateFeatureInput) {
  try {
    return createFeatureApi(feature);
  } catch (error) {
    LOG.error(error);
  }
}
