import { Stack } from '@mui/material';
import { createContext, PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

export interface IPermissions {
  [key: string]: {
    [key: string]: string[];
  };
}

type ContextProps = {
  name: string;
};

export const FormContext = createContext<ContextProps | null>(null);

export type FormContainerProps<T extends FieldValues = FieldValues> = PropsWithChildren<{
  name: string;
  methods: UseFormReturn<T>;
}>;

export const FormContextProvider = <TFieldValues extends FieldValues = FieldValues>({
  name,
  methods,
  children,
}: FormContainerProps<TFieldValues>) => (
  <FormProvider {...methods}>
    <FormContext.Provider value={{ name }}>
      <Stack spacing={2}>{children}</Stack>
    </FormContext.Provider>
  </FormProvider>
);
