import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import { useValidationController } from "hooks/useValidationForm";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { FormFieldType } from "types/forms";

interface BooleanFieldType extends FormFieldType {
  defaultValue?: boolean;
  helpText?: string;
}

const BooleanField = memo(
  ({
    name,
    label,
    disabled = false,
    readOnly = false,
    required = false,
    defaultValue,
    helpText,
  }: BooleanFieldType) => {
    const { control } = useFormContext();

    const { id, field } = useValidationController({
      name,
      control,
      rules: { required },
      defaultValue,
    });

    return (
      <FormGroup>
        <Tooltip title={helpText}>
          <FormControlLabel
            data-cy={id}
            disabled={disabled}
            control={
              <Checkbox
                onChange={field.onChange}
                onBlur={field.onBlur}
                checked={!!field.value}
                name={field.name}
                inputRef={field.ref}
                required={required}
                readOnly={readOnly}
              />
            }
            label={label}
          />
        </Tooltip>
      </FormGroup>
    );
  }
);

export default BooleanField;
