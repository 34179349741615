import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { HubCallback } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import SignInDialog from 'components/auth/SignInDialog';
import Splash from 'components/Splash';
import LOG from '../logging/Logger';

export type AuthenticatorContextType = {
  isLoading: boolean;
  error?: Error;
  session?: CognitoUserSession;
};

export type AuthenticatorProviderProps = {
  children: ReactNode | ((props: AuthenticatorContextType) => ReactNode);
  authConfig: any;
};

export const AuthenticatorContext = createContext<AuthenticatorContextType>({
  isLoading: false,
});

const AuthenticatorProvider = ({ children, authConfig }: AuthenticatorProviderProps) => {
  const [result, setResult] = useState<AuthenticatorContextType>({
    error: undefined,
    isLoading: false,
    session: undefined,
  });

  const fetchCurrentSession = useCallback(async () => {
    setResult((prevResult) => ({ ...prevResult, isLoading: true }));

    try {
      const session = await Auth.currentSession();
      setResult({ isLoading: false, session });
    } catch (e) {
      LOG.info(e);
      setResult((prevResult) => ({ ...prevResult, isLoading: false }));
    }
  }, []);

  const handleAuth: HubCallback = useCallback(
    async ({ payload }) => {
      LOG.info('Event', payload);
      switch (payload.event) {
        // success events
        case 'signIn':
        case 'signUp':
        case 'autoSignIn':
        case 'cognitoHostedUI': {
          await fetchCurrentSession();
          break;
        }
        case 'signOut':
        case 'oAuthSignOut': {
          setResult({ isLoading: false });
          break;
        }

        // failure events
        case 'signIn_failure':
        case 'tokenRefresh_failure':
        case 'customState_failure': {
          setResult({ error: payload.data as Error, isLoading: false });
          break;
        }
        case 'autoSignIn_failure': {
          // autoSignIn just returns error message. Wrap it to an Error object
          setResult({ error: new Error(payload.message), isLoading: false });
          break;
        }

        // events that need another fetch
        case 'tokenRefresh': {
          await fetchCurrentSession();
          break;
        }

        default: {
          // we do not handle other hub events like `configured`.
          break;
        }
      }
    },

    [fetchCurrentSession]
  );

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', handleAuth, 'useAuth');

    return () => unsubscribe();
  }, [handleAuth]);

  useEffect(() => {
    Auth.configure(authConfig);
  }, [authConfig]);

  useEffect(() => {
    (async () => {
      await fetchCurrentSession();
    })();
  }, [fetchCurrentSession]);

  const { session, isLoading, error } = result;

  return (
    <AuthenticatorContext.Provider value={result}>
      {session &&
        !isLoading &&
        (typeof children === 'function' ? children({ ...result }) : children)}
      {!session && !isLoading && <SignInDialog initialError={error?.message} />}
      {isLoading && <Splash />}
    </AuthenticatorContext.Provider>
  );
};

export const useAuthenticator = () => useContext(AuthenticatorContext);

export default AuthenticatorProvider;
