import {
  Autocomplete,
  Chip,
  CircularProgress,
  FilterOptionsState,
  Popper,
  TextField,
} from "@mui/material";
import { useValidationController } from "hooks/useValidationForm";
import isEqual from "lodash/isEqual";
import { Fragment, memo, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormFieldType, OptionType } from "types/forms";
import { convertCamelCaseToKebabCase as toKebab } from "util/dataAttributes";
import { sortByField } from "util/sorting";
import WarningText from "./ adornments/WarningText";
import InfoText from "./ adornments/InfoText";
import { useMountEffect } from "app/hooks";


const valueToOption = (value: string | OptionType<any>) =>
  typeof value === "string"
    ? {
        label: value,
        id: value,
      }
    : value;

const defaultIsOptionEqualToValue = (option: any, value: any) =>
  value ? option.id === (value?.id || value) : false;

export interface ValueAdornment {
  icon?: JSX.Element;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}

export interface AutoCompleteType<T> extends FormFieldType {
  warningText?: string;
  infoText?: string;
  items?: OptionType<T>[];
  loading?: boolean;
  sort?: boolean;
  multiple?: boolean;
  isOptionEqualToValue?(option: OptionType<T>, value: OptionType<T>): boolean;
  filterOptions?(
    options: OptionType<T>[],
    state: FilterOptionsState<OptionType<T>>
  ): OptionType<T>[];
  filterSelectedOptions?: boolean;
  freeSolo?: boolean;
  valueAdornments?: { [value: string]: ValueAdornment };
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  groupBy?(option: any): string;
  type?: string;
}

const AutoComplete = <T extends any>({
  name,
  label,
  disabled = false,
  readOnly = false,
  required = false,
  autoFocus = false,
  warningText,
  infoText,
  items,
  loading = false,
  sort = true,
  multiple = false,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  filterSelectedOptions = multiple,
  filterOptions,
  freeSolo = false,
  valueAdornments = {},
  disableCloseOnSelect = multiple,
  disableClearable,
  groupBy,
  type,
}: AutoCompleteType<T>) => {
  // When using this component, we register the name _meta.${name}_autocomplete
  // and manually set the value of ${name}
  // This is because the autocomplete values are objects {id, label} and we want to validate and return the actual value (id)

  const { control, formState, register, getValues, setValue, getFieldState } =
    useFormContext();
  const { error } = getFieldState(name);

  const pseudoName = useMemo(() => `_meta.${name}_autocomplete`, [name]);
  const options = useMemo(
    (): OptionType<T>[] =>
      sort
        ? sortByField(
            items?.map((item) => valueToOption(item)),
            "label"
          ) || []
        : items || [],
    [items, sort]
  );

  const {
    formId,
    field: { onChange, onBlur, value, ref, ...fieldRest },
  } = useValidationController({
    name: pseudoName,
    control,
    rules: { required },
  });

  const realValue = getValues(name);

  // This is just so we can find the drop down when testing
  const SelectablePopper = useMemo(
    () => (props: any) =>
      (
        <Popper
          data-cy={`${toKebab(formId)}-${toKebab(name)}-menu`}
          {...props}
        />
      ),
    [formId, name]
  );

  useMountEffect(() => {
    register(name);
    setValue(pseudoName, getValues(name));

    // Reset the AutoComplete's internal form value on unmount
    return () => {
      setValue(pseudoName, formState.defaultValues?.[name]);
    };
  });

  // If the value at 'name' changes externally, update our PSEUDO value
  useEffect(() => {
    let fullOptions = [...options];

    if (freeSolo && realValue) {
      // If we're a freeSolo, we may have values that don't exist in the options
      if (multiple) {
        fullOptions = options.concat(
          realValue
            .map((value: any) => valueToOption(value))
            .filter(
              (value: any) => !options.find((option) => option.id === value.id)
            )
        );
      } else if (
        !options.find((option) => option.id === valueToOption(realValue).id)
      ) {
        fullOptions = [...options, valueToOption(realValue)];
      }
    }

    if (multiple) {
      setValue(
        pseudoName,
        fullOptions.filter((item) => realValue?.includes(item.id)),
        { shouldValidate: true }
      );
    } else {
      setValue(
        pseudoName,
        realValue
          ? fullOptions.find((item) => isEqual(item.id, realValue))
          : null,
        { shouldValidate: true }
      );
    }
  }, [freeSolo, multiple, options, pseudoName, realValue, setValue]);

  return (
    <Autocomplete
      data-cy={`${formId}-${toKebab(name)}`}
      groupBy={groupBy}
      filterOptions={filterOptions}
      onChange={(event, values, reason, details) => {
        let newValues = values;

        if (freeSolo) {
          // if we're a freeSolo, we may get back strings, so convert them to OptionType<T>[]
          newValues = multiple
            ? (values as string[] | OptionType<T>[])?.map((value) =>
                valueToOption(value)
              )
            : valueToOption(values as string | OptionType<T>);
        }

        onChange({
          target: {
            value: newValues,
          },
        });

        // Update the real form value
        if (multiple) {
          setValue(
            name,
            (newValues as OptionType<T>[])?.map((value) => value.id),
            { shouldValidate: true }
          );
        } else {
          setValue(name, newValues ? (newValues as OptionType<T>).id : null, {
            shouldValidate: true,
          });
        }
      }}
      onBlur={onBlur}
      value={multiple ? value || [] : value || null}
      fullWidth={true}
      options={options}
      disabled={disabled}
      readOnly={readOnly}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) =>
        `${typeof option === "string" ? option : option.label}`
      }
      getOptionDisabled={(option) => !!option.disabled}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            label={option.label}
            {...getTagProps({ index })}
            {...((typeof option.id === "string" &&
              valueAdornments[option.id]) ||
              {})}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props} key={JSON.stringify(option.id)}>
          {option.label}
        </li>
      )}
      PopperComponent={SelectablePopper}
      renderInput={(params) => (
        <TextField
          required={required}
          label={label}
          {...params}
          error={!!error}
          fullWidth
          autoFocus={autoFocus}
          variant="standard"
          disabled={disabled}
          helperText={error?.message}
          type={type}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                {warningText && <WarningText warningText={warningText} />}
                {infoText && <InfoText infoText={infoText} />}
              </Fragment>
            ),
          }}
          inputRef={ref}
        />
      )}
      loading={loading}
      multiple={multiple}
      filterSelectedOptions={filterSelectedOptions}
      freeSolo={freeSolo}
      autoSelect={freeSolo}
      {...fieldRest}
    />
  );
};

export default memo(AutoComplete) as typeof AutoComplete;
