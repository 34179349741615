import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth } from "aws-amplify";
import Splash from "components/Splash";
import LOG from "../logging/Logger";

export type PermissionContextType = {
  isLoading: boolean;
  groups: string[];
};

export type PermissionProviderProps = {
  children: ReactNode | ((props: PermissionContextType) => ReactNode);
};

export const PermissionContext = createContext<PermissionContextType>({
  isLoading: false,
  groups: [],
});

const PermissionProvider = ({ children }: PermissionProviderProps) => {
  const [result, setResult] = useState<PermissionContextType>({
    groups: [],
    isLoading: false,
  });

  const fetchGroups = useCallback(async () => {
    setResult((prevResult) => ({ ...prevResult, isLoading: true }));

    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.idToken.payload["cognito:groups"] ?? [];
      setResult({ isLoading: false, groups: groups });
    } catch (e) {
      LOG.info(e);
      setResult((prevResult) => ({ ...prevResult, isLoading: false }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchGroups();
    })();
  }, [fetchGroups]);

  const { isLoading, groups } = result;
  return (
    <PermissionContext.Provider value={result}>
      {!isLoading &&
        groups.length > 0 &&
        (typeof children === "function" ? children({ ...result }) : children)}
      {isLoading && <Splash />}
    </PermissionContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionContext);

export default PermissionProvider;
