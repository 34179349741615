import { Box, Typography } from "@mui/material";

interface ContentMetadataProps {
  contentMetadata: any;
}

const ContentMetadata = ({ contentMetadata }: ContentMetadataProps) => {
  if (!contentMetadata || Object.keys(contentMetadata).length === 0) {
    return <div />;
  }

  return (
    <div>
      <Box textAlign="center" sx={{ marginTop: 2 }}>
        <img
          src={contentMetadata.backgroundUrl}
          alt=""
          style={{ height: 200 }}
        />
        {contentMetadata.seasontitle ? (
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {contentMetadata.seasontitle}
          </Typography>
        ) : (
          ""
        )}
        {contentMetadata.t ? (
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {contentMetadata.t}
          </Typography>
        ) : (
          ""
        )}
      </Box>
      {contentMetadata.sy ? (
        <Typography variant="body1">{contentMetadata.sy}</Typography>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentMetadata;
