import { ACSCallManager } from "calling/ACSCallManager";
import { Speaker } from "types/peripherals";
export interface SetSpeakerProps {
  speaker: Speaker;
}

export async function setSpeakerApi(setSpeakerProps: SetSpeakerProps) {
  const callClient = ACSCallManager.getInstance();
  return await callClient.setSpeaker(setSpeakerProps.speaker);
}
