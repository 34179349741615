import { useMemo } from 'react';
import WatchTogetherApp from "./WatchTogetherApp";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, GlobalStyles } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import ToastContainer from 'components/toasts/ToastContainer';

import AuthenticatorProvider from './authenticator/AuthenticatorProvider';
import { authConfig } from "./awsConfig";
import { Provider } from "react-redux";
import { store } from "./app/store";
import PermissionProvider from "permissions/PermissionProvider";
import { glass } from "themes/glass";


const App = () => {
  const theme = useMemo(() => createTheme(glass), []);

  return (
    <BrowserRouter>
      <GlobalStyles
        styles={{
          "html, body, #root": {
            height: "100%",
            // Without this, going from landscape to portrait on Safari,
            // with nav bar open, text zooms in and never back out
            // https://stackoverflow.com/questions/2710764/preserve-html-font-size-when-iphone-orientation-changes-from-portrait-to-landsca
            WebkitTextSizeAdjust: "100%",
          },
        }}
      />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex" }} data-cy="application">
            <CssBaseline enableColorScheme />
            <ToastContainer />
            <AuthenticatorProvider authConfig={authConfig}>
              {({ session }: any) => (
                <PermissionProvider>
                  <WatchTogetherApp />
                </PermissionProvider>
              )}
            </AuthenticatorProvider>
          </Box>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
