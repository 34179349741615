import { Alert, AlertColor, Button } from '@mui/material';
import { toast, Toast } from 'react-hot-toast';

interface ToastContentProps {
  message: string;
  toastId: string;
  severity: AlertColor;
}

const ToastContent = ({ message, toastId, severity }: ToastContentProps) => {
  return (
    <Alert
      variant="filled"
      severity={severity}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => toast.remove(toastId)}
        >
          Dismiss
        </Button>
      }
    >
      {message}
    </Alert>
  );
};

const showToast = (t: Toast, severity: AlertColor, message: string) => (
  <ToastContent severity={severity} message={message} toastId={t.id} />
);

export { showToast };
