import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid";
import { DeleteFeatureInput, Feature } from "types/featureFlags";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";
interface FeatureFlagsDataGridProps {
  features: Feature[];
  handleEdit: (feature: Feature) => void;
  handleDelete: (feature: DeleteFeatureInput) => void;
}

export default function FeatureFlagsDataGrid({
  features,
  handleEdit,
  handleDelete,
}: FeatureFlagsDataGridProps) {
  const [rows, setRows] = useState(features as GridRowsProp);

  useEffect(() => {
    setRows(features as GridRowsProp);
  }, [features]);

  const handleEditClick = (id: GridRowId) => () => {
    const feature = features.filter((feature) => feature.id === id);
    if (feature && feature.length > 0) {
      handleEdit(feature[0]);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const feature = features.filter((feature) => feature.id === id);
    if (feature && feature.length > 0) {
      handleDelete({ id: feature[0].id });
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      type: "boolean",
      width: 200,
      renderCell: (params) => {
        return params.value ? (
          <CheckIcon
            style={{
              color: "#4caf50",
            }}
          />
        ) : (
          <CloseIcon
            style={{
              color: "#ff1744",
            }}
          />
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      width: 200,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      id="feature-flags-data-grid"
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid rows={rows} columns={columns} />
    </Box>
  );
}
