import { PlayCircle } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Room } from "types/room";

interface PlaybackControlProps {
  onPlaybackOffsetChanged: (playbackPosition: number) => any;
  onPlaybackStateChanged: (isPlaying: boolean) => any;
  room: Room;
}

const PlaybackControls = ({
  onPlaybackOffsetChanged,
  onPlaybackStateChanged,
  room,
}: PlaybackControlProps) => {
  const [playbackOffset, setPlaybackOffset] = useState("");
  const onPlaybackOffsetChange = (e: any) => setPlaybackOffset(e.target.value);

  return (
    <div>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button
          onClick={() => {
            onPlaybackOffsetChanged(room.playbackOffset! - 30000);
          }}
        >
          -30s
        </Button>
        <Button
          onClick={() => {
            onPlaybackOffsetChanged(room.playbackOffset! - 15000);
          }}
        >
          -15s
        </Button>
        <Button
          onClick={() => {
            onPlaybackStateChanged(!room.isPlaying!);
          }}
        >
          Play/Pause
        </Button>
        <Button
          onClick={() => {
            onPlaybackOffsetChanged(room.playbackOffset! + 15000);
          }}
        >
          +15s
        </Button>
        <Button
          onClick={() => {
            onPlaybackOffsetChanged(room.playbackOffset! + 30000);
          }}
        >
          +30s
        </Button>
      </ButtonGroup>
      <Typography variant="body1">Change playback offset</Typography>
      <TextField
        autoFocus
        margin="dense"
        id="change-content-dialog-title-textfield"
        label="Custom playback offset in ms"
        helperText="Use positive values to change playback offset ahead. Use negative values to change playback offset behind. For example 2000 will move +2s ahead. -2000 will move -2s behind."
        type="number"
        fullWidth
        variant="standard"
        value={playbackOffset}
        onChange={onPlaybackOffsetChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PlayCircle />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="outlined"
        onClick={() => {
          onPlaybackOffsetChanged(
            room.playbackOffset! + Number(playbackOffset)
          );
        }}
      >
        Set specific time
      </Button>
    </div>
  );
};

export default PlaybackControls;
