import { Logger as AWSLogger } from '@aws-amplify/core';
const { REACT_APP_ENV } = process.env;

const logger = new AWSLogger("wt-web");

declare global {
  interface Window {
    LOG_LEVEL: string;
  }
}
if (typeof window !== "undefined") {
  window.LOG_LEVEL = REACT_APP_ENV === "dev" ? "INFO" : "ERROR";
}

class Logger {
  debug(...msg: any[]) {
    logger.debug(...msg);
  }

  info(...msg: any[]) {
    logger.info(...msg);
  }

  warn(...msg: any[]) {
    logger.warn(...msg);
  }

  error(...msg: any[]) {
    logger.error(...msg);
  }
}

const LOG = new Logger();
export default LOG;
