export interface SnackbarMessage {
  isSuccess: boolean;
  message: string;
}

export function buildSuccessMessage(message: string): SnackbarMessage {
  return {
    isSuccess: true,
    message: message,
  };
}

export function buildErrorMessage(message: string): SnackbarMessage {
  return {
    isSuccess: false,
    message: message,
  };
}
