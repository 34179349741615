import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client";

/**
 * Creates an HTTP link for the specified endpoint and auth properties.
 *
 * @param {String} graphQLEndpoint The HTTP (https:) endpoint to connect to
 * @param {*} authHeader Header properties for authentication
 * @returns
 */
export function createAuthenticatedHttpLink(
  graphQLEndpoint: any,
  authHeader: any
) {
  const httpLink = createHttpLink({
    uri: graphQLEndpoint,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authHeader,
      },
    };
  });

  return authLink.concat(httpLink);
}
