import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import TabPanel from "components/TabPanel";
import { useSimpleTabs } from "hooks/tabs/useSimpleTabs";
import { Stack } from "@mui/system";
import FormErrorItem from "components/form/FormErrorItem";
import { getLocal, saveLocal } from "util/storage";

const SIGN_IN_TAB = "signInTab";

const formatError = (error: string | undefined) => {
  const formatted = error?.replaceAll("+", " ").trim();

  if (formatted === "PreSignUp failed with error Access restricted.") {
    return "Access Restricted";
  }

  return formatted;
};

interface SignInDialogProps {
  initialError?: string;
}

const SignInDialog = ({ initialError }: SignInDialogProps) => {
  const { register, value } = useSimpleTabs({
    defaultValue: getLocal<number>(SIGN_IN_TAB),
    onChange: (value: number) => saveLocal(SIGN_IN_TAB, value),
  });

  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState<string | undefined>();

  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const handleSkySSO = () => {
    setOpen(false);
    Auth.federatedSignIn({ customProvider: "SkySSO" });
  };

  useEffect(() => {
    setErrors(initialError);
  }, [initialError]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <Dialog open={open} fullScreen={fullScreen} data-cy="authentication-dialog">
      <DialogContent>
        <Tabs {...register()}>
          <Tab label="Sky User Login" />
          <Tab label="Partner Login" />
        </Tabs>
        <Box sx={{ pt: 3 }}>
          <TabPanel value={value} identifier={0}>
            <Stack spacing={2}>
              <FormErrorItem error={formatError(errors)} />
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                onClick={handleSkySSO}
                color="primary"
                autoFocus
                fullWidth
                disableFocusRipple
              >
                Sign in with Sky SSO
              </Button>
            </Stack>
          </TabPanel>
          <TabPanel value={value} identifier={1}>
            <Stack spacing={2}>
              <Typography style={{ textAlign: "center" }}>
                Not available
              </Typography>
            </Stack>
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default SignInDialog;
