import React, { memo } from 'react';
import { Typography } from '@mui/material';

type ErrorPageProps = {
  error: string;
};

export default memo(({ error }: ErrorPageProps) => (
  <Typography align="center" variant="h5">
    {error}
  </Typography>
));
