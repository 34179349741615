import { ApiClient } from "api/roomManagement/RoomManagementApiClient";
import { EnvironmentManager } from "calling/EnvironmentManager";
import LOG from "logging/Logger";

export interface SubscribeToRoomUpdatesProps {
  roomCode: string;
  callback: (event: string) => void;
}

export async function subscribeToRoomUpdatesApi(
  subscribeToRoomUpdatesProps: SubscribeToRoomUpdatesProps
) {
  const environmentManager = EnvironmentManager.getInstance();
  const apiDetails = environmentManager.getApiDetails();
  if (!environmentManager.isApiDetailsValid()) {
    throw new Error("Api details not correct");
  }
  LOG.info(
    `Subscribe to room updates for room: ${subscribeToRoomUpdatesProps.roomCode}`
  );
  const apiClient = ApiClient.getInstance(apiDetails!);
  const input = { roomCode: subscribeToRoomUpdatesProps.roomCode };
  const result = apiClient.onRoomUpdate(
    input,
    subscribeToRoomUpdatesProps.callback
  );
  return result;
}
