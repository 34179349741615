import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PlayArrow, Search } from "@mui/icons-material";
interface ChangeContentDialogProps {
  open: boolean;
  handleClose: () => void;
  search: (text: string) => void;
  contentResult: any[];
  selectContent: (content: any) => void;
}

const ChangeContentDialog = ({
  open,
  handleClose,
  search,
  contentResult,
  selectContent,
}: ChangeContentDialogProps) => (
  <div>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select new content</DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <DialogContentText>Type content title</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="change-content-dialog-title-textfield"
          label="Content title"
          type=""
          fullWidth
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            search(event.target.value);
          }}
        />
        <List sx={{ width: "100%", maxWidth: 360 }}>
          {contentResult.map((value) => (
            <ListItem
              key={value.uuid}
              disableGutters
              secondaryAction={
                <IconButton
                  aria-label="play"
                  onClick={() => selectContent(value)}
                >
                  <PlayArrow />
                </IconButton>
              }
            >
              <ListItemText
                primary={value.t}
                onClick={() => selectContent(value)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default ChangeContentDialog;
