import { Fragment, memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, SxProps, Toolbar } from '@mui/material';
import { NavigationItem } from '../data/navigation';
import LOG from '../logging/Logger';
import NotFoundPage from 'pages/errors/NotFoundPage';

interface MainProps {
  navigation: NavigationItem[];
  navigationItem: NavigationItem | undefined;
  setScrollRef(ref: HTMLElement | null): void;
}

const sxMain: SxProps = {
  p: 0.5,
  marginTop: 0.5,
  overflowX: 'hidden',
  overflowY: 'auto',
  height: 'calc(100vh - 85px)',
};

const Main = memo((props: MainProps) => {
  LOG.debug('Rendering: Main');

  const { navigation, setScrollRef } = props;

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
      <Toolbar />
      <Routes>
        {navigation.map((item) => (
          <Fragment key={item.id}>
            {item.paths.map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  <Box sx={sxMain} ref={(el) => setScrollRef(el as HTMLElement)} data-cy="main">
                    <item.component {...props} />
                  </Box>
                }
              />
            ))}
          </Fragment>
        ))}
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Box>
  );
});

export default Main;
