import { memo } from "react";
import { InputAdornment, Tooltip } from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";

interface WarningTextType {
  warningText?: string;
}

const WarningText = memo(({ warningText }: WarningTextType) => (
  <InputAdornment position="end">
    <Tooltip title={warningText}>
      <WarningIcon color="warning" />
    </Tooltip>
  </InputAdornment>
));

export default WarningText;
