import { Link } from '@mui/material';
import React from 'react';

interface LogoProps {
  style?: {};
  link?: string;
}

const Logo = ({ style = {}, link }: LogoProps) => (
  <Link href={link}>
    <picture data-cy="logo">
      <base href="/" />
      <source srcSet="sky-logo.webp" type="image/webp" />
      <img style={style} src="sky-logo.webp" alt="Sky" />
    </picture>
  </Link>
);

export default Logo;
