import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { getEnvironmentsApi } from "usecase/environments/getEnvironments";
import {
  CreateFeatureEnvironmentProps,
  FeatureEnvironment,
  UpdateFeatureEnvironmentInput,
} from "types/featureEnvironments";
import {
  SnackbarMessage,
  buildErrorMessage,
  buildSuccessMessage,
} from "types/snackbarMessage";
import { createEnvironmentApi } from "usecase/environments/createEnvironment";
import { updateEnvironmentApi } from "usecase/environments/updateEnvironment";
import { deleteEnvironmentApi } from "usecase/environments/deleteEnvironment";

export interface FeatureEnvironmentsState {
  isLoading: boolean;
  initialised: boolean;
  environments: FeatureEnvironment[];
  editEnvironment?: FeatureEnvironment;
  message?: SnackbarMessage;
  dialogOpen: boolean;
}

const initialState: FeatureEnvironmentsState = {
  isLoading: false,
  initialised: false,
  environments: [],
  dialogOpen: false,
};

export const initialise = createAsyncThunk(
  "featureEnvironments/initialise",
  async (permissions: String[]) => {
    const environments = await getEnvironmentsApi(permissions);
    return environments;
  }
);

export const createFeatureEnvironment = createAsyncThunk(
  "featureEnvironments/createEnvironment",
  async (props: CreateFeatureEnvironmentProps) => {
    return createEnvironmentApi(props.input, props.sourceEnvironment);
  }
);

export const updateFeatureEnvironment = createAsyncThunk(
  "featureEnvironments/updateEnvironment",
  async (input: UpdateFeatureEnvironmentInput) => {
    return updateEnvironmentApi(input);
  }
);

export const deleteFeatureEnvironment = createAsyncThunk(
  "featureEnvironments/deleteEnvironment",
  async (environment: FeatureEnvironment) => {
    return deleteEnvironmentApi(environment);
  }
);

export const featureEnvironmentsSlice = createSlice({
  name: "featureEnvironments",
  initialState,
  reducers: {
    clearMessage(state) {
      state.message = undefined;
    },
    setDialogOpen(state, action) {
      state.dialogOpen = action.payload;
    },
    setEditEnvironment(state, action) {
      state.editEnvironment = action.payload;
    },
  },
  extraReducers: (builder) => {
    return builder
      .addCase(initialise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialise.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          state.environments = result;
          state.isLoading = false;
        }
        state.initialised = true;
      })
      .addCase(initialise.rejected, (state, action) => {
        state.message = buildErrorMessage(
          `Failed to select environments: ${action.error.message}`
        );
        state.initialised = true;
        state.isLoading = false;
      })
      .addCase(createFeatureEnvironment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFeatureEnvironment.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          state.environments = [...state.environments, result];
          state.isLoading = false;
          state.message = buildSuccessMessage("Environment created.");
        }
      })
      .addCase(createFeatureEnvironment.rejected, (state, action) => {
        state.message = buildErrorMessage(
          `Failed to create environment: ${action.error.message}`
        );
        state.isLoading = false;
      })
      .addCase(updateFeatureEnvironment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFeatureEnvironment.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedEnv = action.payload;
          const index = state.environments.findIndex(
            (env) => env.id === updatedEnv.id
          );
          const envs = state.environments;
          envs[index] = updatedEnv;
          state.environments = envs;
        }
        state.isLoading = false;
        state.message = buildSuccessMessage("Environment updated.");
      })
      .addCase(updateFeatureEnvironment.rejected, (state, action) => {
        state.message = buildErrorMessage(
          `Failed to update environment: ${action.error.message}`
        );
        state.isLoading = false;
      })
      .addCase(deleteFeatureEnvironment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFeatureEnvironment.fulfilled, (state, action) => {
        if (action.payload) {
          const deletedEnvironment = action.payload;
          state.environments = state.environments.filter(
            (env) => env.id !== deletedEnvironment.id
          );
        }
        state.isLoading = false;
        state.message = buildSuccessMessage("Environment deleted.");
      })
      .addCase(deleteFeatureEnvironment.rejected, (state, action) => {
        state.isLoading = false;
        state.message = buildErrorMessage(
          `Failed to delete environment: ${action.error.message}`
        );
      });
  },
});
export const { clearMessage, setDialogOpen, setEditEnvironment } =
  featureEnvironmentsSlice.actions;
export const selectFeatureEnvironmentsState = (state: RootState) =>
  state.featureEnvironments;

export default featureEnvironmentsSlice.reducer;
