import { Fragment, memo, useCallback, useMemo } from "react";
import {
  Box,
  Drawer as MuiDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  ListItem,
} from "@mui/material";
import TabPanel from "components/TabPanel";
import { NavigationItem } from "data/navigation";
import { useTabsContext } from "hooks/tabs/useTabsContext";
import useResize from "hooks/useResize";
import { version } from "version";

const drawerWidth = 300;

interface DrawerProps {
  smallScreen?: boolean;
  navigation: NavigationItem[];
  selectedItemId: string | undefined;
  onSelect(item: NavigationItem): void;
  onToggleDrawer(): void;
  openDrawer: boolean;
}

const Drawer = memo(
  ({
    smallScreen = false,
    navigation,
    selectedItemId,
    onSelect,
    onToggleDrawer,
    openDrawer,
  }: DrawerProps) => {
    const { width, enableResize } = useResize({ minWidth: drawerWidth });

    const { tabs, selectedTab } = useTabsContext();

    const footer = useMemo(() => {
      return (
        <List style={{ marginTop: `auto` }}>
          <ListItem>
            <ListItemText>v. {version}</ListItemText>
          </ListItem>
        </List>
      );
    }, []);

    const handleNavigationSelect = useCallback(
      (index: number, tab: string | undefined) => {
        onToggleDrawer();
        if (tab) {
          onSelect(navigation.filter((item) => item.tab === tab)[index]);
        } else {
          onSelect(navigation[index]);
        }
      },
      [navigation, onSelect, onToggleDrawer]
    );

    const getNavigationItemsForTab = useCallback(
      (tab?: string) => {
        return (
          <Fragment>
            {navigation &&
              navigation
                .filter((item) => item.tab === tab)
                .map((item, index) => {
                  if (item.sidebar === undefined || item.sidebar) {
                    return (
                      <ListItemButton
                        key={item.id}
                        selected={item.id === selectedItemId}
                        onClick={(event) => handleNavigationSelect(index, tab)}
                      >
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <ListItemText id={item.id} primary={item.title} />
                      </ListItemButton>
                    );
                  } else {
                    return <Fragment key={item.id} />;
                  }
                })}
          </Fragment>
        );
      },
      [navigation, selectedItemId, handleNavigationSelect]
    );

    const drawer = useMemo(() => {
      return (
        <Box sx={{ overflow: "hidden" }} data-cy="navigation">
          <List>
            {getNavigationItemsForTab()}

            {tabs.map((tab) => (
              <TabPanel
                key={tab}
                value={selectedTab || tabs[0]}
                identifier={tab}
              >
                {getNavigationItemsForTab(tab)}
              </TabPanel>
            ))}
          </List>
        </Box>
      );
    }, [selectedTab, tabs, getNavigationItemsForTab]);

    return (
      <Fragment>
        {smallScreen ? (
          <MuiDrawer
            variant="temporary"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: {
                  xs: "100",
                  sm: "50%",
                },
                minWidth: {
                  xs: "100%",
                  sm: drawerWidth,
                },
                boxSizing: "border-box",
              },
            }}
            open={openDrawer}
            onClose={onToggleDrawer}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Toolbar />
            {drawer}
            {footer}
          </MuiDrawer>
        ) : (
          <MuiDrawer
            variant="permanent"
            sx={{
              width,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width,
                boxSizing: "border-box",
                overflow: "hidden",
              },
            }}
          >
            <Toolbar />
            {drawer}
            <Box
              sx={{
                position: "absolute",
                width: "10px",
                top: "0",
                right: "-1px",
                bottom: "0",
                cursor: "col-resize",
              }}
              onMouseDown={enableResize}
              onClick={(e) => e.stopPropagation()}
            />
            {footer}
          </MuiDrawer>
        )}
      </Fragment>
    );
  }
);


export default Drawer;
