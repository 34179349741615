import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import { GetFeaturesInput } from "types/featureEnvironments";
import { Feature } from "types/featureFlags";

export async function getFeaturesApi(input: GetFeaturesInput) {
  const listFeaturesResult =
    await RemoteManagementApiClient.getInstance().getFeatures(input);
  if (listFeaturesResult) {
    return listFeaturesResult.getFeatures as Feature[];
  } else {
    return [];
  }
}
