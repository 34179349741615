export const getEnvironmentsQuery = `
query GetEnvironmentsQuery {
  getEnvironments {
    cognitoGroupName
    description
    id
    name
  }
}
`;

export const createEnvironmentMutation = `
mutation CreateEnvironmentMutation($input: CreateEnvironmentInput!) {
  createEnvironment(input: $input) {
    cognitoGroupName
    description
    id
    name
  }
}
`;

export const updateEnvironmentMutation = `
mutation UpdateEnvironmentMutation($input: UpdateEnvironmentInput!) {
  updateEnvironment(input: $input) {
    cognitoGroupName
    description
    name
    id
  }
}
`;

export const deleteEnvironmentMutation = `
mutation DeleteEnvironmentMutation($input: DeleteEnvironmentInput!) {
  deleteEnvironment(input: $input) {
    id
  }
}`;

export const getFeaturesQuery = `
  query GetFeaturesQuery($input: GetFeaturesInput!) {
    getFeatures(input: $input) {
      description
      enabled
      environmentId
      id
      name
    }
  }
`;

export const createFeatureMutation = `
mutation CreateFeatureMutation($input: CreateFeatureInput!) {
  createFeature(input: $input) {
    id
    name
    description
    enabled
    environmentId
  }
}`;

export const updateFeatureMutation = `
mutation UpdateFeatureMutation($input: UpdateFeatureInput!) {
  updateFeature(input: $input) {
    id
    name
    description
    enabled
    environmentId
  }
}`;

export const deleteFeatureMutation = `
mutation DeleteFeatureMutation($input: DeleteFeatureInput!) {
  deleteFeature(input: $input) {
    description
    enabled
    environmentId
    id
    name
  }
}`;
