import {
  FluentThemeProvider,
  VideoGallery,
  darkTheme,
} from "@azure/communication-react";
import { Box, Stack } from "@mui/material";
import { LocalParticipant, RemoteParticipant } from "types/participant";
import { getParticipantView } from "usecase/calling/getParticipantView";

interface ParticipantsVideoGalleryProps {
  localParticipant: LocalParticipant;
  remoteParticipants: RemoteParticipant[];
}

export const ParticipantsVideoGallery = ({
  localParticipant,
  remoteParticipants,
}: ParticipantsVideoGalleryProps) => {
  const localView = localParticipant.hasView
    ? getParticipantView("local")
    : undefined;

  var localParticipantConfig = {
    userId: "user1",
    displayName: "You",

    isMuted: !localParticipant.micEnabled,
    videoStream: {},
  };
  if (localParticipant.cameraEnabled && localParticipant.hasView) {
    localParticipantConfig.videoStream = {
      isAvailable: localParticipant.cameraEnabled,
      isReceiving: localParticipant.cameraEnabled,
      renderElement: localView?.target,
    };
  }

  const remoteParticpiantsConfig = remoteParticipants.map(
    (participant: RemoteParticipant) => {
      const view = participant.hasView
        ? getParticipantView(participant.uuid)
        : undefined;

      return {
        userId: participant.uuid,
        displayName: participant.uuid,
        isMuted: !participant.micEnabled,
        videoStream:
          view && participant.cameraEnabled
            ? {
                isAvailable: view !== undefined,
                isReceiving: view !== undefined,
                renderElement: view?.target,
              }
            : undefined,
      };
    }
  );
  return (
    <Box>
      {localView ? (
        <Stack style={{ height: "50dvh" }}>
          <FluentThemeProvider fluentTheme={darkTheme}>
            <VideoGallery
              onRenderAvatar={(userId, options, defaultOnRender) => {
                return (options && defaultOnRender?.(options)) ?? <></>;
              }}
              maxRemoteVideoStreams={12}
              localParticipant={localParticipantConfig}
              remoteParticipants={remoteParticpiantsConfig}
              overflowGalleryPosition="verticalRight"
            />
          </FluentThemeProvider>
        </Stack>
      ) : (
        <div />
      )}
    </Box>
  );
};
