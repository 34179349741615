import { Fragment, memo, useCallback, useState } from 'react';
import {
  AppBar as MuiAppBar,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import Logo from 'components/Logo';
import { Box } from '@mui/system';
import { useTabsContext } from 'hooks/tabs/useTabsContext';
import { Link } from 'react-router-dom';

interface AppBarProps {
  smallScreen?: boolean;
  isCallingBackend?: boolean;
  onToggleDrawer(): void;
  onLogout(): void;
}

const AppBar = memo(
  ({ smallScreen = false, isCallingBackend = false, onToggleDrawer, onLogout }: AppBarProps) => {
    const { tabs, selectedTab, changeTab } = useTabsContext();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleOpenMenu = useCallback((event: any) => setMenuAnchorEl(event.currentTarget), []);
    const handleCloseMenu = useCallback(() => setMenuAnchorEl(null), []);
    const handleLogout = useCallback(() => onLogout(), [onLogout]);

    return (
      <MuiAppBar
        position="fixed"
        sx={{
          zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Fragment>
          <Toolbar>
            {smallScreen && (
              <IconButton
                aria-label="Open drawer"
                onClick={onToggleDrawer}
                sx={{
                  mr: 1,
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Logo style={{ height: 30 }} link="/" />

            {!smallScreen && (
              <Typography
                variant="h6"
                component={Link}
                to="/"
                reloadDocument={true}
                sx={{
                  pl: 3,
                  flexGrow: 1,
                  fontWeight: 500,
                  color: "inherit",
                  textDecoration: "none",
                }}
                data-cy="title"
              >
                Watch Together Web
              </Typography>
            )}

            {tabs.length > 1 && !smallScreen && (
              <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
                <Tabs
                  value={selectedTab}
                  onChange={(e, newValue: string) => changeTab(newValue)}
                  variant="fullWidth"
                  aria-label="navigation tabs"
                >
                  {tabs.map((tab) => (
                    <Tab
                      sx={{ fontSize: "1.2rem" }}
                      key={tab}
                      value={tab}
                      label={tab}
                      data-cy={`navigation-tab-${tab.toLowerCase()}`}
                    />
                  ))}
                </Tabs>
              </Box>
            )}

            {smallScreen && (
              <Box
                sx={{
                  flexGrow: 1,
                }}
              />
            )}

            <IconButton
              aria-owns={menuAnchorEl ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleOpenMenu}
              data-cy="account-menu"
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={handleCloseMenu}
            >
              {smallScreen && tabs.length > 1 && (
                <Fragment>
                  {tabs.map((tab) => (
                    <MenuItem
                      key={tab}
                      sx={{
                        backgroundColor:
                          selectedTab === tab ? "primary.main" : undefined,
                      }}
                      onClick={() => {
                        changeTab(tab);
                        handleCloseMenu();
                        onToggleDrawer();
                      }}
                    >
                      {tab}
                    </MenuItem>
                  ))}
                  <Divider />
                </Fragment>
              )}
              <MenuItem onClick={handleLogout} data-cy="logout">
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
          {isCallingBackend && <LinearProgress style={{ height: 1 }} />}
        </Fragment>
      </MuiAppBar>
    );
  }
);

export default AppBar;
