import React, { Fragment, memo } from "react";
import { Button, Typography } from "@mui/material";
import SectionCard from "components/SectionCard";

const ContactPage = memo(() => {
  return (
    <Fragment>
      <SectionCard>
        <Typography align="center" variant="h5">
          Contact
        </Typography>
        <Typography align="center" variant="body1" sx={{ marginTop: 4 }}>
          Please use slack channel to contact with Watch Together Web team:
        </Typography>
        <Typography align="center">
          <Button href="https://sky.enterprise.slack.com/archives/C06737QKHK7">
            #wt-web-users
          </Button>
        </Typography>
      </SectionCard>
    </Fragment>
  );
});

export default ContactPage;
