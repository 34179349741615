import FormDialog from "components/form/FormDialog";
import BooleanField from "components/form/fields/BooleanField";
import TextField from "components/form/fields/TextField";
import { Fragment, memo } from "react";
import { FieldValues } from "react-hook-form";
import {
  FeatureEnvironment,
} from "types/featureEnvironments";
import {
  CreateFeatureInput,
  Feature,
  UpdateFeatureInput,
} from "types/featureFlags";

export interface FeatureFlagDialogProps {
  feature?: Feature;
  environment: FeatureEnvironment;
  open: boolean;
  handleCancel: () => void;
  handleEdit: (input: UpdateFeatureInput) => void;
  handleSave: (input: CreateFeatureInput) => void;
}

const FeatureFlagDialog = memo(
  ({
    feature,
    environment,
    open,
    handleCancel,
    handleEdit,
    handleSave,
  }: FeatureFlagDialogProps) => {
    const isCreate = feature === undefined;
    const title = isCreate ? "Create feature flag" : "Edit feature flag";

    const onSave = (data: FieldValues) => {
      if (isCreate) {
        handleSave({
          name: data.name,
          description: data.description ?? "",
          enabled: data.enabled,
          environmentId: environment.id,
        });
      } else {
        handleEdit({
          id: data.id,
          name: data.name,
          description: data.description ?? "",
          enabled: data.enabled,
          environmentId: environment.id,
        });
      }
    };

    return (
      <Fragment>
        <FormDialog
          name="featureFlagDialog"
          title={title}
          open={open}
          onSave={onSave}
          onCancel={handleCancel}
          allowSaveUnchanged={true}
          defaultValues={feature}
        >
          <TextField name="name" label="Name" required={true} />
          <TextField name="description" label="Description" required={false} />
          <BooleanField name="enabled" label="Enabled" defaultValue={true} />
        </FormDialog>
      </Fragment>
    );
  }
);
export default FeatureFlagDialog;
