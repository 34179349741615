import RemoteManagementApiClient from "api/remoteManagement/RemoteManagementApiClient";
import { FeatureEnvironment } from "types/featureEnvironments";

/**
 * Currently due to problems, backend can't handle filtering.
 * TODO: Handle filtering on backend side. 
 */
export async function getEnvironmentsApi(groupNames: String[]) {
  const listEnvironmentsResult =
    await RemoteManagementApiClient.getInstance().getEnvironments();

  if (listEnvironmentsResult) {
    var environments = listEnvironmentsResult.getEnvironments;

    if (groupNames.length > 0) {
      environments = environments.filter((item: any) =>
        groupNames.includes(item.cognitoGroupName)
      );
    }
    return environments.map(
      (val: any): FeatureEnvironment => ({
        id: val.id,
        cognitoGroupName: val.cognitoGroupName,
        name: val.name,
        description: val.description,
      })
    );
  } else {
    return [];
  }
}
