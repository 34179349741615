const {
  REACT_APP_REGION,
  REACT_APP_COGNITO_USER_POOL,
  REACT_APP_COGNITO_USER_POOL_CLIENT,
  REACT_APP_COGNITO_IDENTITY_POOL,
  REACT_APP_COGNITO_USER_POOL_DOMAIN,
  REACT_APP_REDIRECT_URL,
} = process.env;

export const authConfig = {
  userPoolId: REACT_APP_COGNITO_USER_POOL,
  userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT,
  region: REACT_APP_REGION,
  identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL,
  oauth: {
    domain: REACT_APP_COGNITO_USER_POOL_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: REACT_APP_REDIRECT_URL,
    redirectSignOut: REACT_APP_REDIRECT_URL,
    responseType: "code",
    client_id: REACT_APP_COGNITO_USER_POOL_CLIENT,
  },
  federationTarget: "COGNITO_USER_POOLS",
  authenticationFlowType: "CUSTOM_AUTH",
};
