import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormFieldType } from 'types/forms';
import { InputAdornment, TextField as MuiTextField, Tooltip, Typography } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import { MAX_NAME_LENGTH } from 'data/limits';
import { useValidationController } from 'hooks/useValidationForm';
import { convertCamelCaseToKebabCase as toKebab } from 'util/dataAttributes';

interface TextFieldType extends FormFieldType {
  maxLength?: number;
  helpText?: string;
  maskFunction?(value: string): string;
  variant?: 'standard' | 'filled' | 'outlined';
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  select?: boolean;
  type?: string;
  children?: JSX.Element[];
  prefix?: string;
  suffix?: string;
  fullWidth?: boolean;
  minWidth?: number;
  autoResize?: boolean;
  startResizeWidth?: number;
}

const TextField = memo(
  ({
    name,
    label,
    disabled = false,
    readOnly = false,
    required = false,
    autoFocus = false,
    maxLength = MAX_NAME_LENGTH,
    helpText,
    maskFunction,
    variant = 'standard',
    multiline = false,
    minRows,
    maxRows,
    select = false,
    type,
    children,
    prefix,
    suffix,
    fullWidth = true,
    minWidth = 0,
    autoResize = false,
  }: TextFieldType) => {
    const { control, setValue } = useFormContext();
    const [width, setWidth] = useState(minWidth);

    const {
      id,
      field: { onChange, value, ref, ...fieldRest },
      fieldState: { error },
    } = useValidationController({
      name,
      control,
      rules: { required, maxLength },
    });

    const span = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      if (autoResize && span.current) {
        const offsetWidth = span.current.offsetWidth;
        setWidth(offsetWidth);
      }
    }, [autoResize, setValue, minWidth, value, prefix, suffix]);

    return (
      <Fragment>
        {autoResize && (
          <Typography
            component="span"
            ref={span}
            style={{
              position: 'absolute',
              opacity: 0,
              zIndex: -100,
              whiteSpace: 'pre',
              paddingLeft: 30,
            }}
          >
            {prefix + (value || '') + suffix}
          </Typography>
        )}
        <MuiTextField
          data-cy={id}
          onChange={(e) => {
            onChange(e);
            if (maskFunction) {
              setValue(name, maskFunction(e.target.value));
            }
          }}
          value={value ?? ''}
          inputRef={ref}
          {...fieldRest}
          autoFocus={autoFocus}
          label={label}
          fullWidth={fullWidth}
          variant={variant}
          required={required}
          disabled={disabled}
          error={!!error}
          helperText={error ? error.message : null}
          select={select}
          type={type}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                // @ts-ignore
                'data-cy': `${toKebab(id)}-menu`,
              },
            },
          }}
          inputProps={{ maxLength, 'data-cy': `${id}-input` }}
          InputProps={{
            readOnly,
            endAdornment: (
              <Fragment>
                {suffix && <InputAdornment position="end">{suffix}</InputAdornment>}
                {helpText && (
                  <Tooltip title={helpText}>
                    <HelpIcon />
                  </Tooltip>
                )}
              </Fragment>
            ),
            ...(prefix && {
              startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
            }),
            ...(autoResize && {
              style: { width, minWidth },
            }),
          }}
        >
          {children}
        </MuiTextField>
      </Fragment>
    );
  }
);

export default TextField;
