import { Typography } from '@mui/material';
import { Fragment, memo } from 'react';

interface FormErrorItemProps {
  error?: string;
}

const FormErrorItem = memo(({ error }: FormErrorItemProps) => (
  <Fragment>
    {error && (
      <Typography sx={{ color: 'error.main' }}>{error.toString()}</Typography>
    )}
  </Fragment>
));

export default FormErrorItem;
